import React, { Component } from 'react';
import Header from '../Layouts/Header';

import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import {apiUrl, mediaUrl } from "../Config/Config";
import Axios from 'axios';
import parse from 'html-react-parser';
import { useParams, useNavigate } from 'react-router-dom'

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class View extends Component {

  constructor(props)
  {
    super(props); 
    const configproductid = (typeof this.props.params.ID === 'undefined') ? '' : this.props.params.ID;
    this.state = {      
      configproductid: configproductid,
      configproductlistview:'',
      configproductimglist:'',
      configtryoutimglist: ''
    };

    var qs = require('qs');
    var postObject = {             
      id: configproductid
    };
   
    Axios.post(apiUrl+"configureproduct/view",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){
        this.setState({ configproductlistview: res.data.configproductlistview[0], configproductimglist: res.data.configproductimglistview, configtryoutimglist: res.data.configtryoutlistview });
      }
    });  
  }

  componentDidMount() {
    document.title = PageTitle('Config Product View');
  }

  componentWillReceiveProps(Props){
   
  }

  // productthumblist(){
  //   let configproductimglist = this.state.configproductimglist;
  //   let productthumbimage = configproductimglist.length>0 && configproductimglist.map((img, index) => {
  //     if(img.type == 'thumb'){
  //       let thumb_img = img.image;
  //       let thumb_img_path = img.image_path;
  //       return(<img src={mediaUrl+thumb_img_path+thumb_img} width={200} height={200} alt={"Thumb Image"+index+1} />);
  //     }
  //   });
  //   return productthumbimage;
  // }

  // tryoutthumblist(){
  //   let configproductimglist = this.state.configtryoutimglist;
  //   let tryoutthumbimage =configproductimglist.length>0 && configproductimglist.map((img,index) => {
  //     if(img.type == 'thumb'){
  //       let thumb_img = img.tryout_image;
  //       let thumb_img_path = img.tryout_image_path;
  //       return(<img src={mediaUrl+thumb_img_path+thumb_img} width={200} height={200} alt={"Thumb Image"+index+1} />);
  //     }
  //   });
  //   return tryoutthumbimage;
  // }
  tryoutthumblist() {
    let configproductimglist = this.state.configtryoutimglist;
    let tryoutthumbimage = configproductimglist.length > 0 && configproductimglist.map((img, index) => {
      if(img.type === 'thumb') {
        let thumb_img = img.tryout_image;
        let thumb_img_path = img.tryout_image_path;
        return (
          <img src={mediaUrl + thumb_img_path + thumb_img} width={200} height={200} alt={"Thumb Image" + (index + 1)} />
        );
      }
    });
    // Check if there are thumbnail images to display
    if (tryoutthumbimage && tryoutthumbimage.length > 0) {
      return (
        <>

          <div className="view-details">
              <div className="view-details-one">
                  <p><b>Thumbnail Images:<span></span> </b></p>             
              </div>
              <div className="view-details-two">
              {tryoutthumbimage}
              </div>
          </div>
        </>
      );
    } else {
      return null; // Return null if there are no thumbnail images
    }
  }
  // configimageblist(){
  //   let configproductimglist = this.state.configproductimglist;
  //   console.log(configproductimglist,'configproductimaglist')
  //   let productthumbimage = configproductimglist.length>0 && configproductimglist.map((img, index) => {
  //     if(img.type == 'main'){
  //       let thumb_img = img.config_image;
  //       let thumb_img_path = img.config_image_path;
  //       return(<img src={mediaUrl+thumb_img_path+thumb_img} width={200} height={200} alt={"Thumb Image"+index+1} />);
  //     }
  //   });
  //   return productthumbimage;
  // }
  configimageblist() {
    let configproductimglist = this.state.configproductimglist;
    console.log(configproductimglist,'configproductimaglist')
    let productthumbimage = configproductimglist.length > 0 && configproductimglist.map((img, index) => {
      if(img.type === 'main') {
        let thumb_img = img.config_image;
        let thumb_img_path = img.config_image_path;
        return (
          <img src={mediaUrl + thumb_img_path + thumb_img} width={200} height={200} alt={"Thumb Image" + (index + 1)} />
        );
      }
    });
  
    // Check if there are images to display
    if (productthumbimage && productthumbimage.length > 0) {
      return (
        <>
         
          <div className="view-details">
              <div className="view-details-one">
                  <p><b>Configure Image<span>:</span> </b></p>                 
              </div>
              <div className="view-details-two">
                  {productthumbimage}
              </div>
          </div>
        </>
      );
    } else {
      return null; // Return null if there are no images
    }
  }
  render() {
    var configproductlist = this.state.configproductlistview;
    let configproductimglist=this.state.configproductimglist;
    let configtryoutimglist=this.state.configtryoutimglist;

    const navigate = this.props.navigate;
    let productmain_img = '';
    let productmain_img_path = '';
    let tryyoutmain_img = '';
    let tryyoutmain_img_path = '';

    let name = configproductlist.product_name != null && configproductlist.product_name !== '' ? configproductlist.product_name.charAt(0).toUpperCase()+ configproductlist.product_name.slice(1) : '';

    let short_description = configproductlist.short_description != null && configproductlist.short_description !== '' ? configproductlist.short_description.charAt(0).toUpperCase()+ configproductlist.short_description.slice(1) : '';

    let description = configproductlist.description != null && configproductlist.description !== '' ? configproductlist.description.charAt(0).toUpperCase()+ configproductlist.description.slice(1) : '';
    
   
        productmain_img = configproductlist.image;
        productmain_img_path = configproductlist.image_path;
    
    configproductlist.length>0 && configproductlist.map((pdctimg) => {
      
      if(pdctimg.type == 'main'){
        productmain_img = pdctimg.image;
        productmain_img_path = pdctimg.image_path;
      }
    });
   
    configtryoutimglist.length>0 && configtryoutimglist.map((value) => {
      if(value.type == 'main'){
        tryyoutmain_img = value.tryout_image;
        tryyoutmain_img_path = value.tryout_image_path;
      }
    });
    
    return (
      <div className="wrapper"> 
 
        <Header />
        <MenuSidebar currentpage="config-product" />  
      
        <div className="content"> 
          <div className="content-wrapper">
            <div className="content-body">
                <div className="form-wrapper">
                  <div className="back-btn-lux">
                    <a onClick={() => navigate(-1)}>Back</a>    
                  </div> 
                  <div className="title">
                    <h3>Configure Item View</h3>
                  </div>
                  <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Product Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{name}</p>
                          </div>
                        </div>

                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Product Code<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.configproductlistview.product_code}</p>
                          </div>
                        </div>

                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Price<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>${this.state.configproductlistview.price}</p>
                          </div>
                        </div>

                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Category<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.configproductlistview.label}</p>
                          </div>
                        </div>

                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Short Description<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.configproductlistview.short_description != '' && this.state.configproductlistview.short_description != null ? parse(short_description) : 'NA'}</p>
                          </div>
                        </div>

                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Description<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.configproductlistview.description != '' && this.state.configproductlistview.description != null ? parse(description) : 'NA'}</p>
                          </div>
                        </div>

                        
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{(this.state.configproductlistview.status=='A')?'Active':'Inactive'}</p>
                          </div>
                        </div>

                        <div className="view-details-one">
                            <p><b>Product Image: </b></p>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Main Image<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <img src={mediaUrl+productmain_img_path+productmain_img} width={200} height={200} alt="Product Image" />
                          </div>
                        </div>
                        {/* <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Thumbnail Images<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              {this.productthumblist()}
                          </div>
                        </div> */}

                        {/* <div className="view-details-one">
                            
                        </div> */}
                        {/* <div className="view-details">
                          
                          <div className="view-details-two"> */}
                         
                              {/* <img src={mediaUrl+tryyoutmain_img_path+tryyoutmain_img} width={200} height={200} alt="Tryout Image" /> */}
                          {/* </div> */}
                        {/* </div> */}
                        {this.configimageblist()}
                        {this.tryoutthumblist()}
                        {/* <div className="view-details">
                          <div className="view-details-two">
                              
                          </div>
                        </div> */}
                    </div>
                  </div>
                </div>            
            </div>
          </div> 
        </div>        
      </div>
    );
  }
}

export default (withRouter(View));