import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { useParams, useNavigate } from 'react-router-dom';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
	
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			brandname:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			displayColorPicker: false,
			
		};

		this.handleInputChange = this.handleInputChange.bind(this);
	
    }
	  
	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   };

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }
 
  handleClick = () => {
	this.setState({displayColorPicker: !this.state.displayColorPicker })
	};
  handleClose = () => {
	this.setState({ displayColorPicker: false })
	};

	



  handleSubmit = () => {
	if(this.validateForm()){
		const formdata = this.state;
		var qs = require('qs');
		var status = '';

		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}

		if(formdata.selectedvalue === ''){
			status =formdata.selectedOption.value;
		}else{
			status = formdata.selectedvalue;
		}

		var img_name = this.state.image;
		var postObject ={
			admin_id:  localStorage.getItem("admin_id"),
			brandname : formdata.brandname,
			status: status
		}

		let formData = new FormData();
		for(let k in postObject) {
			formData.append(k, postObject[k]);
		}
		axios.post(apiUrl+"brand/add",formData,config).then(res => {
			if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							setTimeout(
							function() {
								this.props.navigate('/brand');
								// $('.success_message').html(this.props.history.push('/color'));
							}
							.bind(this),
							3000
							);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		}
		
	}
	validateForm() {
		const {brandname,color,hexColor} = this.state;
		console.log(this.state,'vali');
		let errors = {};
      	let formIsValid = true;

      	if(!brandname){
        	formIsValid = false;
        	$('.errorbrandname').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorbrandname').html('');
		}

		
		this.setState({
			errors: errors
		});
		return formIsValid;
    }

  render() {
	const navigate = this.props.navigate;
	
	let preview = '';
	const {selectedOption} = this.state;

	var current = this;	

    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="brand" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
            </div>
			<div className="title">
				<h4>Add Brand</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Brand Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="brandname" onChange={this.handleInputChange} className="form-control" value={this.state.brandname}/>
					<div className="errorbrandname"></div>
				</div>
				
			</div>
			<div className="form-right">
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
                            onChange={this.handleChange}
                            />
						<div className="errorstatus"></div>
	          	 </div> 
			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));