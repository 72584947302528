import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl, mediaUrl } from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";

import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { useParams, useNavigate, Link } from 'react-router-dom';
import MyEditor from '../Membership/MyEditor';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Edit extends Component {
	fileObj = [];
    fileArray = [];
    imageArray = [];
	fileObj1 = [];
	tryoutfileArray = [];
	tryimageArray = [];
	constructor(props)
	{
		super(props);	
		const configproductId = (typeof this.props.params.ID === 'undefined') ? '' : this.props.params.ID;
		this.state = {
			configproductId: configproductId,
			name:'',
			product_code: '',
			price: '',
			qty:'',
			short_description: '',
			description: '',
			product_gallery_image: '',
			tryout_gallery_image: '',
			image_preview:'',
			image_name:'',
			image:'',
			fileExtension: '',
			tryout_image_preview:'',
			tryout_image_name:'',
			tryout_image:'',
			tryout_fileExtension: '',
			trialon: 'main',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			configproductlist: '',
			productgalleries: '',
			tryoutgalleries: '',
			selectedCateOption: '',
		};

		this.onEditorChange = this.onEditorChange.bind();

		
		
		axios.get(apiUrl+"configureproduct/listdetail?id="+configproductId).then(res => {
			let product_main_image = '';
			let tryout_main_image = '';
			let trial_on = '';
			let productthumb_image = [];
			let tryoutthumb_image = [];

			console.log(res.data.configproductlist,'testingconfigproduct')
			let productmain_img = res.data.configproductlist.image;
			let productmain_img_path = res.data.configproductlist.image_path;
			product_main_image = mediaUrl+productmain_img_path+productmain_img;
			var catData = {label : res.data.configproductlist.label , value : res.data.configproductlist.value};
			res.data.configproductimglistview.length>0 && res.data.configproductimglistview.map((pdctimg) => {
				
				// if(pdctimg.type == 'main'){
				// 	let productmain_img = pdctimg.image;
				// 	let productmain_img_path = pdctimg.image_path;
				// 	product_main_image = mediaUrl+productmain_img_path+productmain_img;
				// }else if(pdctimg.type == 'thumb'){
				// 	productthumb_image.push(pdctimg);
				// }
			});
			
			res.data.configtryoutlistview.length>0 && res.data.configtryoutlistview.map((value) => {
				
				if(value.type == 'main'){
					let tryyoutmain_img = value.tryout_image;
					let tryyoutmain_img_path = value.tryout_image_path;
					trial_on = value.trial_on;
					tryout_main_image = mediaUrl+tryyoutmain_img_path+tryyoutmain_img;
				}else if(value.type == 'thumb'){
					tryoutthumb_image.push(value);
				}
			});
			this.setState({selectedCateOption:catData });
			this.setState({ productgalleries: productthumb_image, tryoutgalleries: tryoutthumb_image});
			this.setState({configproductlist: res.data.configproductlist, name:res.data.configproductlist.product_name, product_code:res.data.configproductlist.product_code, price:res.data.configproductlist.price, short_description: res.data.configproductlist.short_description, description: res.data.configproductlist.description, image_preview: product_main_image, tryout_image_preview: tryout_main_image, trialon: trial_on});
			if(res.data.configproductlist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
		});

		this.handleInputChange = this.handleInputChange.bind(this);
    }

	componentDidMount() {
		this.categoryListData()
	}

	
	categoryListData(postobject=''){
		var qs = require('qs');
		var admin_id = localStorage.getItem("admin_id");
		var loggedas = localStorage.getItem("loggedas");
		var postobject = {
			activePage: 1,
			search_all: '',
			admin_id: admin_id,
			loggedas: loggedas
		};    

		axios.post(apiUrl+"ProductCategory/list",qs.stringify(postobject)).then(res => {
			if(res.data.status === 'success'){
				var categorylist = res.data.categorylist;
				let i=0;
				var categorylists = [];
				for(i; i<categorylist.length; i++){
					categorylists[i] = { value: categorylist[i]['id'], label: categorylist[i]['name'] };
					console.log(categorylist,"categorylists12");
				}
				console.log(categorylists,"categorylists");
				this.setState({  categorylist:categorylists });
			}
		}); 
	  }
	  
	handleChange = selectedOption => {
        this.setState({selectedCateOption:selectedOption})
			this.setState({  selectedCatevalue : selectedOption.value});
   	};

   	handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  	}

	confirmPopup = (id,imagepath,selected_image,type) => {
		this.setState({selectid: id,imagepath:imagepath,selected_image:selected_image, type: type})	
		$('.confirm-action').addClass('show');		
	}

	deleteimage(){
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          galerry_id  :this.state.selectid,
          id  :this.state.configproductId,
          imagepath  :this.state.imagepath,
          selected_image  :this.state.selected_image,
		  type: this.state.type
        };
        axios.post(apiUrl+"configureproduct/deleteimage",qs.stringify(postObject)).then(res => {      
          	if(res.data.status === 'success'){
				if(this.state.type == 'product'){
					this.setState({productgalleries:res.data.productgalleries})
				}else{
					this.setState({tryoutgalleries:res.data.tryoutgalleries})
				}
				$('.success_message').html('<div class="status_sucess"><h3> '+ res.data.message +' </h3></div>');
				setTimeout(function() {
					$('.success_message').html('');   
				}.bind(this),3000);
          	}
        }); 
	}

	uploadMultipleFiles(arg,e){
		if(arg=='product'){
			this.fileObj = [];
			this.fileObj.push(e.target.files)
			for (let i = 0; i < this.fileObj[0].length; i++) {
				this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
				this.imageArray.push(this.fileObj[0][i])
			}
			this.setState({ product_gallery_image: this.imageArray })
		}else{
			this.fileObj1 = [];
			this.fileObj1.push(e.target.files)
			for (let i = 0; i < this.fileObj1[0].length; i++) {
				this.tryoutfileArray.push(URL.createObjectURL(this.fileObj1[0][i]))
				this.tryimageArray.push(this.fileObj1[0][i])
			}
			this.setState({ tryout_gallery_image: this.tryimageArray })
		}	
	}

	removeImagebyindex = (indexvalue) => {
		var list = [...this.imageArray];
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);
	
		for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
		}
	
		this.setState({ product_gallery_image: this.imageArray })
		
	}

	removeTryoutImagebyindex = (indexvalue) => {
		var trylist = [...this.tryimageArray];
		this.tryimageArray = [];
		this.tryoutfileArray = [];
		trylist.splice(indexvalue, 1);

		for (let i = 0; i < trylist.length; i++) {
		this.tryimageArray.push(trylist[i]);
		this.tryoutfileArray.push(URL.createObjectURL(trylist[i]));
		}

		this.setState({ tryout_gallery_image: this.tryimageArray })
	}

	componentWillReceiveProps(Props){
		console.log('Props');
	}

	onEditorChange = ( evt ) => {
		var current = this;
		setTimeout(function () {
		current.setState( {
			short_description: evt
		} );
		}.bind(this),1000);
	}

	onSelcectImage(arg,event){
		if(arg == 'product'){
			let reader = new FileReader();
			const file = event.target.files[0];
			const extension = file.name.split('.').pop();
			reader.onloadend = () => {
				this.setState({
				image_name: file.name,
				image_preview: reader.result,
				image: file,
				fileExtension:extension,
				
				});
			};
			reader.readAsDataURL(file);
		}else{
			let tryoutreader = new FileReader();
			const tryoutfile = event.target.files[0];
			const extension1 = tryoutfile.name.split('.').pop();
			tryoutreader.onloadend = () => {
				this.setState({
					tryout_image_name: tryoutfile.name,
					tryout_image_preview: tryoutreader.result,
					tryout_image: tryoutfile,
					tryout_fileExtension:extension1,
				});
			};
			tryoutreader.readAsDataURL(tryoutfile);
		}
	}

   	handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
  	}

  	handleSubmit = () => {
		if(this.validateForm()){ 
			const payload = this.state;
			var qs = require('qs');
			var status = '';
			var cate = '';
			

			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			}

			let formData = new FormData();
			var imagedata = payload.product_gallery_image;
			for (var i in imagedata) {
				formData.append('images[]',imagedata[i])
			}

			var tryimagedata = payload.tryout_gallery_image;
			for (var i in tryimagedata) {
				formData.append('tryoutimages[]',tryimagedata[i])
			}

			if(payload.selectedvalue === ''){
				status =payload.selectedOption.value;
			}else{
				status = payload.selectedvalue;
			}

			if(payload.selectedCatevalue === '' && payload.selectedCatevalue!== 0){
				cate =payload.selectedCateOption.value;
			}else{
				cate = payload.selectedCatevalue;
			}

			formData.append('id',payload.configproductId)
			formData.append('admin_id',localStorage.getItem("admin_id"))
			formData.append('name',payload.name)
			formData.append('product_code',payload.product_code)
			formData.append('price',payload.price)
			formData.append('short_description',payload.short_description)
			formData.append('description',payload.description)
			formData.append('status',status)
			formData.append('cat_id',cate)
			formData.append('image',payload.image)
			formData.append('tryout_image',payload.tryout_image)
			formData.append('trialon',payload.trialon)
			console.log(payload,"payload");
			console.log(formData,"formData");
			
			axios.post(apiUrl+"configureproduct/edit",formData,config).then(res => {

				if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(function() {
						this.props.navigate('/config-product');
					}.bind(this),3000);

				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(function() {
						$('.success_message').html('');
					}.bind(this),3000);
				}
			});
		}
	}

	Confirmstatus = (confirmstatus) => {
		if(confirmstatus == 'yes'){
		  this.deleteimage();
		  $('.confirm-action').removeClass('show');
		}else{
		  $('.confirm-action').removeClass('show');	
		}
		  
	}

	validateForm() {
		const {name,product_code,price,image,product_gallery_image,tryout_image,tryout_gallery_image,qty} = this.state;
		const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
		let errors = {};
      	let formIsValid = true;

		let maxSize = 1000000;//1 mb
		// if (!product_gallery_image) {
		// 	errors++;
		// 	$('.erroruser_image').html('<span class="errorspan">Please fill the field</span>');
		// }else if(product_gallery_image.length > 10){
				  
		// 	errors++;
		// 	$('.erroruser_image').html('<span class="errorspan">You can only upload up to 10 images</span>');
				   
		// }else{
		// 	for (let i = 0; i < product_gallery_image.length; i++) 
		// 	{
		// 		const file = product_gallery_image[i];
		// 		const filename = file.name;
		// 		console.log(filename,"filename")
		// 		if (file.size > maxSize) {
		// 			errors++;
		// 		$('.erroruser_image').html('<span class="errorspan">'+filename+'File is too large. Max size is 1MB</span>');
		// 		continue;
		// 		}
		// 		if (!allowedTypes.includes(file.type)) {
		// 			errors++;
		// 		$('.erroruser_image').html('<span class="errorspan"> '+filename+' file type is Invalid.</span>');
		// 		continue;
		// 		}
		// 	}
		// }

		// if (!tryout_gallery_image) {
		// 	errors++;
		// 	$('.errortryout_image').html('<span class="errorspan">Please fill the field</span>');
		// }else if(tryout_gallery_image.length > 10){
				  
		// 	errors++;
		// 	$('.errortryout_image').html('<span class="errorspan">You can only upload up to 10 images</span>');
				   
		// }else{
		// 	for (let i = 0; i < tryout_gallery_image.length; i++) 
		// 	{
		// 		const file1 = tryout_gallery_image[i];
		// 		const filename1 = file1.name;
		// 		console.log(filename1,"filename")
		// 		if (file1.size > maxSize) {
		// 			errors++;
		// 		$('.errortryout_image').html('<span class="errorspan">'+filename1+'File is too large. Max size is 1MB</span>');
		// 		continue;
		// 		}
		// 		if (!allowedTypes.includes(file1.type)) {
		// 			errors++;
		// 		$('.errortryout_image').html('<span class="errorspan"> '+filename1+' file type is Invalid.</span>');
		// 		continue;
		// 		}
		// 	}
		// }

      	if(!name){
        	formIsValid = false;
        	$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorname').html('');
		}

		if(!product_code){
        	formIsValid = false;
        	$('.errorproductcode').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorproductcode').html('');
		}

		if(!price){
        	formIsValid = false;
        	$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorprice').html('');
		}

		// if (!image) {
		// 	formIsValid = false;
		// 	$('.errorimage').html('<span class="errorspan">Please fill the field</span>');
		// }else if(image){
		// 	$('.errorimage').html('');
		// }

		// if (!tryout_image) {
		// 	formIsValid = false;
		// 	$('.errortryoutimage').html('<span class="errorspan">Please fill the field</span>');
		// }else if(image){
		// 	$('.errortryoutimage').html('');
		// }

		// if(!qty){
        // 	formIsValid = false;
        // 	$('.errorqty').html('<span class="errorspan">Please fill the field</span>');
		// }else{
			
		// 	$('.errorqty').html('');
		// }
		
		this.setState({
			errors: errors
		});
		return formIsValid;
	}


  render() {
	const { selectedCateOption} = this.state;
	const navigate = this.props.navigate;
	const {selectedOption, productgalleries, tryoutgalleries} = this.state;

	let imageslist = [];
	let indexlength =  imageslist.length;

	const imgThumblist =  this.fileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index,'prodcut')}>X</a>
		</li>
	));

	const imgtryoutThumblist = this.tryoutfileArray.map((url,index) => (
		<li className="thumb" key={index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeTryoutImagebyindex.bind(this,index,'tryout')}>X</a>
		</li>
	));
		
	var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;
	console.log(preview,'privieimage')
	var	tryoutpreview = <img className="img_class" src={this.state.tryout_image_preview} alt="" />;

    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="config-product" />  

		<div id="location-error"></div>
		<div className="content">	
			<div className="content-wrapper">
				<div class="content-wrapper-before"></div>
					<div className="form-wrapper leaa-admin-head card">
					<div className="success_message"></div>
					<div className="back-btn-lux">
						<a onClick={() => navigate(-1)}>Back</a>    
					</div>
					<div className="title">
						<h4>Edit Configure Item</h4>				
					</div>
					<form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
						<div className="form-row">
							<div className="form-left">
								<div className="form-group">
									<label>Product Name<span class="required" style={{ color: "red" }} > * </span> : </label>
									<input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name}/>
									<div className="errorname"></div>
								</div>

								<div className="form-group">
									<label>Short Description<span class="required" style={{ color: "red" }} > </span></label>
									<MyEditor initialData={this.state.short_description} onEditorChange={this.onEditorChange}></MyEditor>
								</div>

								<div className="form-group">
									<label>Market Price ( $ )<span class="required" style={{ color: "red" }} > * </span> : </label>
									<input type="number" name="price" onChange={this.handleInputChange} className="form-control" value={this.state.price}/>
									<div className="errorprice"></div>
								</div>

								<div className="form-group">
									<label>Category<span class="required" style={{ color: "red" }} > * </span> : </label>
									<Select 
									value={selectedCateOption?selectedCateOption:""}
										options={this.state.categorylist} 
										onChange={this.handleChange.bind(this)}
										/>
										<div className="errorcate"></div>
								</div>

								
								
								{/* <div className="form-group">
									<label>Configure Image<span class="required" style={{ color: "red" }} > </span></label>
									<div className="choose-file">
									{this.state.isHidden && <div className="image_success"></div>}
									<span className="profile_btn"><input type="file" name="file" onChange={this.onSelcectImage.bind(this,'tryout')} /></span>
									</div>
									{tryoutpreview}
									<div className="errortryoutimage"></div>  
								</div> */}

								{/* <div className="form-group">
									<label>Trial On<span class="required" style={{ color: "red" }} >* </span></label>
									<input type="radio" value="main" name="trialon" className='trialon' checked={this.state.trialon === 'main'}  onChange={this.handleInputChange}  />Main Image

									<input type="radio" value="element" name="trialon" className='trialon' checked={this.state.trialon === 'element'}  onChange={this.handleInputChange}  />Element Image

									
								</div> */}

							</div>
								
							<div className="form-right">
								<div className="form-group">
									<label>Product Code<span class="required" style={{ color: "red" }} > * </span> : </label>
									<input type="text" name="product_code" onChange={this.handleInputChange} className="form-control" value={this.state.product_code}/>
									<div className="errorproductcode"></div>
								</div>
								{/* <div className="form-group">
									<label>Qty<span class="required" style={{ color: "red" }} > * </span> : </label>
									<input type="text" name="qty" onChange={this.handleInputChange} className="form-control" value={this.state.qty}/>
									<div className="errorqty"></div>
								</div> */}

								<div className="form-group">
									<label>Description<span class="required" style={{ color: "red" }} ></span></label>
									{/* <MyEditor  onEditorChange={this.onEditorChange}></MyEditor> */}
									<CKEditor
										editor={ ClassicEditor }
										data={this.state.description}
										onReady={ editor => {
											// You can store the "editor" and use when it is needed.
											console.log( 'Editor is ready to use!', editor );
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											this.setState({description: data});
											console.log( data,"data");
										} }
									/>
								</div>
								<div className="form-group">
								<label>Upload Image: (Allowed jpeg, png, svg)<span class="required" style={{ color: "red" }} ></span></label>
									
								</div>

								<div className="form-group">
								
									<div className="choose-file">
									{this.state.isHidden && <div className="image_success"></div>}
									<span className="profile_btn"><input type="file" name="file" onChange={this.onSelcectImage.bind(this,'product')} /></span>
									</div>
									{preview}
									<div className="errorimage"></div>  
								</div> 
								<div className="form-group">
									<label> </label>
									<p></p>
								</div>
								
								{/* <div className="form-row">
									<div className="form-left">
										{productgalleries!=''?
										<div className="form-group">
											<div className='gallery-main'>
												<label>Main Image Galleries</label>
												<div className='gallery-container'>
													{productgalleries.map((item) => (
													<div className='gallery-inner' key={item.rehab_gallery_id}>
														<img width="100px" height="100px" src={mediaUrl+item.image_path+'/'+item.image} alt={`Image ${item.id}`}  />
														
														<Link onClick={this.confirmPopup.bind(this, item.id,item.image_path,item.image,'product')} className="" title="Delete">X</Link>
													</div>
													))}
												</div>
											</div>
										</div>:''}		
									</div>
								</div> */}

								{/* <div className="form-group"> 
									<label>Upload Main Thumbnail Image<span class="required" style={{ color: "red" }} > </span></label>
									<div className="choose-file">
										{this.state.isHidden && <div className="image_success"></div>}
										<span className="profile_btn">
										<input type="file" className="form-control" name="file" onChange={this.uploadMultipleFiles.bind(this,'product')} multiple /></span>
									</div>
									<ul className="carimagelisting">
										{imgThumblist}
									</ul>
									<div className="erroruser_image"></div>
								</div> */}

								{/* <div className="form-row">
									<div className="form-left">
										{tryoutgalleries!=''?
										<div className="form-group">
											<div className='gallery-main'>
												<label>Configure Galleries</label>
												<div className='gallery-container'>
													{tryoutgalleries.map((item) => (
													<div className='gallery-inner' key={item.rehab_gallery_id}>
														<img width="100px" height="100px" src={mediaUrl+item.tryout_image_path+'/'+item.tryout_image} alt={`Image ${item.tryout_id}`}  />
														
														<Link onClick={this.confirmPopup.bind(this, item.tryout_id,item.tryout_image_path,item.tryout_image,'tryout')} className="" title="Delete">X</Link>
													</div>
													))}
												</div>
											</div>
										</div>:''}		
									</div>
								</div> */}

								{/* <div className="form-group"> 
									<label>Configure Thumbnail Image<span class="required" style={{ color: "red" }} > </span></label>
									<div className="choose-file">
										{this.state.isHidden && <div className="image_success"></div>}
										<span className="profile_btn">
										<input type="file" className="form-control" name="file" onChange={this.uploadMultipleFiles.bind(this,'tryout')} multiple /></span>
									</div>
									<ul className="carimagelisting">
										{imgtryoutThumblist}
									</ul>
									<div className="erroruser_image"></div>
								</div> */}

								{/* <div className="form-group">					
									<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
									<Select 
									name="status"
									value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
									options={lang.common.status_option} 
									isSearchable = {false}
									onChange={this.handleChange}
									/>
									<div className="errorstatus"></div>
								</div> */}
							</div>	
						</div>		
						<div className="btn-group export">	
							<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >	Submit</button>
						</div>
					</form>
				</div>
				
				<div className="confirm-action">
					<p>Are you sure! you want to delete this image?</p>
					<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
					<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
				</div>
			</div>	
		</div>
    </div>
    );
  }
}


export default (withRouter(Edit));