import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			product_name:'',
			price:'',
			image:'',
			loader_image_preview:'',
			loader_image_name: '',
			loader_image: 'file',
			description:'',
		    cat_name:'',
			store_name: '',
			description:'',
			categorynamelist:[],
			storenamelist: [],
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			selectedProductOption:{ value: '2', label: 'Shopping Product'},
			colornamelist:[],
			product_color:'',
			brand_name:'',
			brandnamelist:[],
			
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleProductChange = this.handleProductChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
    }
	  
	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   };
   handleProductChange = selectedProductOption => {
	this.setState({selectedProductOption})
	this.setState({  selectedproductvalue : selectedProductOption.value});
	var productType = selectedProductOption.value;

	if(productType==1){
		$('.pricediv').hide();
	}else{
		$('.pricediv').show();
	}
	
};
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
  }
  handleClickSelecetCategory = (event) => {
	const selectedIndex = event.target.options.selectedIndex;
	var keys = event.target.options[selectedIndex].getAttribute('data-key');
	this.setState({
			cat_name: keys,		
	});
}

handleClickSelecetStore = (event) => {
	const selectedIndex = event.target.options.selectedIndex;
	var keys = event.target.options[selectedIndex].getAttribute('data-key');
	this.setState({
			store_name: keys,		
			cat_name: ''
	});
	this.categorylistdata(keys);
}

storelistloadedData(postobject=''){
	var admin_id = localStorage.getItem("admin_id");
	var accesscode = localStorage.getItem("company_code");  

   axios.get(apiUrl+'product/getstorenamelist?admin_id='+admin_id+'&accesscode='+accesscode).then(res => {
	 if(res.data.status === 'success'){
		this.setState({  storenamelist: [{value: '', label: 'Select Store'}].concat(res.data.storelist)});
	 }else{
	  	this.setState({  storelist: ''});
	 }
   }); 
  }

	categorylistdata = (store_name) => {
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'product/getcategorynamelist?admin_id='+admin_id+'&store_id='+store_name)
		
		.then(res => {
			if(res.data.status == 'success'){
			
				this.setState({
					categorynamelist: [{value: '', label: 'Select Category'}].concat(res.data.categorynamelist)
				});
			}else{
				
			}
		});
	}
	brandlistloadedData = (store_name) => {
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'product/getbradlist?admin_id='+admin_id+'&store_id='+store_name)
		
		.then(res => {
			if(res.data.status == 'success'){
			
				this.setState({
					brandnamelist: [{value: '', label: 'Select Category'}].concat(res.data.brandnamelist)
				});
			}else{
				
			}
		});
	}
	
	componentDidMount() {
		this.storelistloadedData();
		this.brandlistloadedData();
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'product/getcolornamelist?admin_id='+admin_id)
			.then(res => {
				if(res.data.status == 'success'){

					this.setState({
						colornamelist: [{value: '', label: 'Select Color'}].concat(res.data.colornamelist)
					});
				
				}else{
				//console.log("test")
				}
			});
	}
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		});
		};
		reader.readAsDataURL(file);
	}

	imageRemove = () => {
		// Your logic to remove the selected image
		this.setState({
		  image_name: '',
		  image_preview: '',
		  image: null
		});
	  };
  handleSubmit = () => {
	if(this.validateForm()){
		
	const formdata = this.state;
	var qs = require('qs');
	var status = '';

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}

	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}

/* 	if(formdata.selectedproductvalue === '')
	{
		var product_type =formdata.selectedProductOption.value;
	}else{
		var product_type = formdata.selectedproductvalue;
	} */

	var img_name = this.state.image;
	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		product_name : formdata.product_name,
		price: formdata.price,
		category_id:formdata.cat_name,
		store_id:formdata.store_name,
		description: formdata.description,
		image : formdata.image,
		status: status,
		/* product_type: product_type, */
		product_color_id: formdata.product_color,
		brand_id: formdata.brand_id,
		brand_name: formdata.brand_name,
	}
	

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"product/add",formData,config).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
							this.props.navigate('/product')
						}
						.bind(this),
						3000
						);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
			}
		});
		
	}
  }
	validateForm() {
		const {product_color,product_name,cat_name,store_name,
		price, brand_name,brand_id, description,
		// quantity,
		prescription_detail,selectedproductvalue} = this.state;

		let errors = {};
      	let formIsValid = true;

		
      	if(!cat_name){
        	formIsValid = false;
        	$('.errorcategory_name').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorcategory_name').html('');
		}
		
		if(!description){
        	formIsValid = false;
        	$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errordescription').html('');
		}
		if(!store_name){
        	formIsValid = false;
        	$('.errorstore_name').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorstore_name').html('');
		}
		if(!brand_id){
        	formIsValid = false;
        	$('.errorbrand_name').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorbrand_name').html('');
		}
		if (!product_name) {
			formIsValid = false;
			$('.errorproduct_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(product_name){
			$('.errorproduct_name').html('');
		}
		if (!price) {
			formIsValid = false;
			$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
		}else if(price){
			$('.errorprice').html('');
		}
		if (!product_color) {
			formIsValid = false;
			$('.errorproduct_color').html('<span class="errorspan">Please fill the field</span>');
		}else if(product_color){
			$('.errorproduct_color').html('');
		}
		// if(!selectedproductvalue){
        // 	formIsValid = false;
        // 	$('.errorproductype').html('<span class="errorspan">Please select product type</span>');
		// }else{
			
		// 	$('.errorproductype').html('');
		// }
		// if(selectedproductvalue==2||selectedproductvalue==3){
		// 	if (!price) {
		// 		formIsValid = false;
		// 		$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
		// 	}else if(price){
		// 		$('.errorprice').html('');
		// 	}
		// }
		
		// if (!quantity) {
		// 	formIsValid = false;
		// 	$('.errorquantity').html('<span class="errorspan">Please fill the field</span>');
		// }else if(quantity){
		// 	$('.errorquantity').html('');
		// }
     
		this.setState({
			errors: errors
		});
		return formIsValid;
    }
	handleClickSelecetColor = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var keys = event.target.options[selectedIndex].getAttribute('data-key');
	
		this.setState({
				product_color: keys,	
		});
	
	}
	handleSelectBrand = (event) => {
		const selectedBrand = event.target.value;

		const selectedBrandObject = this.state.brandnamelist.find(
		  (brand) => brand.value === selectedBrand
		);
		this.setState({
			brand_name: selectedBrandObject.label,
			brand_id: selectedBrandObject.value,
		});

	  };
  render() {
	const navigate = this.props.navigate;
	let preview = '';
	const {selectedOption,categorynamelist, product_color, colornamelist,storenamelist, brandnamelist, selectedProductOption,selectedproductvalue} = this.state;

	var current = this;	

    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="Product" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
            </div> 
			<div className="title">
				<h4>Add Product</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Product Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="product_name" onChange={this.handleInputChange} className="form-control" value={this.state.medicine_name}/>
					<div className="errorproduct_name"></div>
				</div>
				{/* <div className="form-group">
					<label>Quantity<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="quantity" onChange={this.handleInputChange} className="form-control" value={this.state.quantity}/>
					<div className="errorquantity"></div>
				</div> */}
				
				<div className="form-group">
					<label>Category<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <select  onChange={current.handleClickSelecetCategory}  value={categorynamelist.cat_name}>                    
                    {this.state.categorynamelist.map((categorynamelist) => <option key={categorynamelist.value} data-key={categorynamelist.value} value={categorynamelist.value} >{categorynamelist.label}</option>)}
                    </select>
					<div className="errorcategory_name"></div>
				 </div>

				<div className="form-group">					
					<label>Product Description:</label>
						<textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.prescription_detail} />
					<div className="errordescription"></div>
				</div>	
				<div className="form-group">	
					<label>Color<span class="required" style={{ color: "red" }} > * </span> : </label>

				    <select  name="product_color"  onChange={current.handleClickSelecetColor}  value={colornamelist.name}>                    
                    {this.state.colornamelist.map((colornamelist) => <option key={colornamelist.value} data-key={colornamelist.value} value={colornamelist.value} >{colornamelist.label} </option>)}
                    </select> 
					<div className="errorproduct_color"></div>
				</div>
				<div className="form-group">
					<label>Brand<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <select  onChange={current.handleSelectBrand}  value={brandnamelist.brand_id}>                    
                    {this.state.brandnamelist.map((brandnamelist) => <option key={brandnamelist.value} data-key={brandnamelist.value} value={brandnamelist.value} >{brandnamelist.label}</option>)}
                    </select>
					<div className="errorbrand_name"></div>
				</div>
				
				</div>
			
			<div className="form-right">
				
				{/* <div className="form-group">					
					<label>Product Type<span class="required" style={{ color: "red" }} > * </span> :</label>
						<Select 
							name="product_type"
							value={selectedProductOption?selectedProductOption:{ value: '2', label: 'Shopping Product'}}
							options={lang.common.productType_option} 
							isSearchable = {false}
							onChange={this.handleProductChange}
						/>
	           	</div>  */}
				<div className="form-group">
					<label>Stores<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <select  onChange={current.handleClickSelecetStore}  value={storenamelist.store_name}>                    
                    {this.state.storenamelist.map((storenamelist) => <option key={storenamelist.value} data-key={storenamelist.value} value={storenamelist.value} >{storenamelist.label}</option>)}
                    </select>
					<div className="errorstore_name"></div>
				</div>

				<div className="form-group pricediv">
					<label>Price ($)<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="price" onChange={this.handleInputChange} className="form-control" value={this.state.price}/>
					<div className="errorprice"></div>
				</div>
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						<Select 
							name="status"
							value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
							options={lang.common.status_option} 
							isSearchable = {false}
							onChange={this.handleChange}
						/>
	           	</div> 
				
				<div className="form-group">
					<label>Image:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{!this.state.image_preview && (
					<div className="form-group">
						{preview}
						
					</div>
					)}
					<div className="errorloaderimage"></div> 
					{this.state.image_preview && (
						<div className="image-preview">
						<img src={this.state.image_preview} alt="Preview" className='img_class' />
						<a href="javascript:void(0);" onClick={this.imageRemove.bind(this, this.state.productId)}>X</a>
						</div>
					)}  
				</div> 
				
			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));