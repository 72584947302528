import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useParams, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap"; 

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      colorlist:'',
      domainlist:[],
      data_status:'',
      selectedStatus:'',
      selectid:'',
      data_status:'',
     
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id,
      loggedas: loggedas
      };  

      axios.post(apiUrl+"color/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  colorlist: res.data.colorlist, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
            
              this.colorlist();
            });
  
        }
      }); 
   }
   tablistload(postobject=''){
    var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id,
      loggedas: loggedas
      };  

      axios.post(apiUrl+"color/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  colorlist: res.data.colorlist, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
            
              this.colorlist();
            });
  
        }
      }); 
  }
   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getcolorlist(qs.stringify(postobject))
    axios.post(apiUrl+"color/list",qs.stringify(postobject)).then(res => {
      
      if(res.data.status === 'success'){
          this.setState({  colorlist: res.data.colorlist, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
            this.colorlist();
          });

      }
    }); 

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    loggedas: loggedas         
  };  
  // this.props.getcolorlist(qs.stringify(postObject));
  axios.post(apiUrl+"color/list",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){

        this.setState({  colorlist: res.data.colorlist, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
          this.colorlist();
        });

    }else if(res.data.status === 'error'){
      
      this.setState({  data_status: res.data.status}, function() {
        this.colorlist();
      });
    }

  }); 

} 



   componentWillReceiveProps(Props){

     if (Props.deletecolor!== this.props.deletecolor) {
      if (Props.deletecolor[0]["status"] === "success") {
         
          $('.success_message').html('<div class="status_sucess"><h3>'+ Props.deletecolor[0]["message"]+'</h3></div>');
           var qs = require('qs');
              var admin_id = localStorage.getItem("admin_id");
              var postobject = {
              activePage: 1,
              search_all: '',
              admin_id: admin_id
              };

              // this.props.getcolorlist(qs.stringify(postobject));
              axios.post(apiUrl+"color/list",qs.stringify(postobject)).then(res => {
                if(res.data.status === 'success'){
           
                    this.setState({  colorlist: res.data.colorlist, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
                      this.colorlist();
                    });
          
                }
              }); 
           setTimeout(
          function() {
          $('.success_message').html('');
          //window.location.reload(); 
          }
          .bind(this),
          3000
          );


          this.setState({colorlist: Props.deletecolor[0]["colorlist"]}, function() {
            this.colorlist();
          });       
          }
          
      }

       if(Props.coloractive !== this.props.coloractive){
      if (Props.coloractive[0]["status"] === "success") {

        $('.success_message').html('<div class="status_sucess"><h3>'+ Props.coloractive[0]["message"]+'</h3></div>');
              var qs = require('qs');
              var admin_id = localStorage.getItem("admin_id");
              var postobject = {
              activePage: 1,
              search_all: '',
              admin_id: admin_id
              };

              // this.props.getcolorlist(qs.stringify(postobject));
  axios.post(apiUrl+"color/list",qs.stringify(postobject)).then(res => {
    if(res.data.status === 'success'){

        this.setState({  colorlist: res.data.colorlist, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
          this.colorlist();
        });

    }
  }); 


           
           setTimeout(
          function() {
          $('.success_message').html('');
          //window.location.reload(); 
          }
          .bind(this),
          3000
          );

      }
    }
     
   }
   
   
//    deletecolor(id){
//     var qs = require('qs');
//     var postObject = {
//       admin_id : localStorage.getItem("admin_id"),
//       id  :id
//     };

//   axios.post(apiUrl+"color/delete",qs.stringify(postObject)).then(res => {
//     if(res.data.status === 'success'){       
//           window.location.reload(); 
//     }
//   }); 
// }
confirmPopup = (id, status) => {
  var status = status;
  console.log(status,'statusnew')
    if(status == 'Inactive'){
      status = "Active";
    }else if(status == 'Active'){
      status = "Inactive";
    }else{
      status="Delete";
    }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}
activatecolor(){
        let stat = ''
        if(this.state.selectedStatus ==='Inactive'){
          stat = "I";
        }else{
          stat = "A";
        } 
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :this.state.selectid,
          status: stat
        };

      axios.post(apiUrl+"color/change_status",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){
        
          this.tablistload();
          $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
       
          setTimeout(
            function() {
           
              $('.success_message').html('');
               
            }
            .bind(this),
            3000
            );
        }
      }); 
}
deletetab(){
var qs = require('qs');
var postObject = {
  admin_id : localStorage.getItem("admin_id"),
  id  :this.state.selectid,
};

axios.post(apiUrl+"color/delete",qs.stringify(postObject)).then(res => {
if(res.data.status === 'success'){  
  $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
    setTimeout(
      function() {
        $('.success_message').html('');
         
      }
      .bind(this),
      3000
      );     
      window.location.reload(); 
}
}); 
}

Confirmstatus =(confirmstatus)=>{
  console.log(this.state.selectedStatus,'confirmstatus')
  if(confirmstatus == 'yes'){
      if(this.state.selectedStatus=="Delete"){
        this.deletetab()
      }else{
        this.activatecolor();
      }
      $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
}

colorlist() {
  let catImg = '';
  let preview = '';
    var colorlist = this.state.colorlist;
    var data_status = this.state.data_status;
    if (colorlist != "undefined" && colorlist != null) {
      
      if (data_status == "success") {

        if (Object.keys(colorlist).length > 0) {
          const helperlistDetails = colorlist.map(
            (colorlist, Index) => {
              let sno = Index+1;
              var color_code;

               if(colorlist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              color_code = colorlist.color_code;

              var name =
              colorlist.name.charAt(0).toUpperCase()
              + colorlist.name.slice(1)
              return (
                <tr key={colorlist.id}>
      					<td>{sno}</td>
      					<td>{name}</td>
                <td>{status}</td>
   
                <td class="actiongroup">
                    <Link to={{ pathname: '/color-view/'+colorlist.id}} className="" title="View"> <i className="ft-eye" aria-hidden="true"></i>   </Link>
                    <Link to={{ pathname: '/color-edit/'+colorlist.id}} className="" title="Edit"> <i className="ft-edit-3" aria-hidden="true"></i></Link>  
                    {(status == 'Active'?<Link onClick={this.confirmPopup.bind(this, colorlist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, colorlist.id, status)} className="" title="Status"><i className="fa ft-lock" aria-hidden="true"></i></Link>)}  
                    <Link onClick={this.confirmPopup.bind(this,colorlist.id)} className="" title="Duplicate"><i className="ft-trash-2" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Color" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Colors</h3>
                </div>
               <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div> 
                 <div className="add_button">  
                    <a className="" href='/color-add'>Add</a>
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Name</th>
                  <th>Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.colorlist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      <div className="confirm-action">
          <p>Are you sure! you want to {this.state.selectedStatus} the Color?</p>
          <a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
          <a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		  </div>
    </div>
    );
  }
}
export default (withRouter(List));