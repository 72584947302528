import React, { Component } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, mediaUrl,categoryURL } from'../Config/Config';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const categoryid = (typeof this.props.params.ID === 'undefined') ? '' : this.props.params.ID;

    this.state = {      
      categoryid: categoryid,
      categorylistdata:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :categoryid
    };

    axios.post(apiUrl+"ProductCategory/view",qs.stringify(postObject)).then(res => {
      console.log(res);
      if(res.data.status === 'success'){
          this.setState({ categorylistdata: res.data.categorylistview[0]});

      }
    }); 
  }

   componentDidMount() {
     document.title = 'Category View';
   }
  render() {
    const navigate = this.props.navigate;
  if(this.state.categorylistdata!==undefined){
    var categorylistdata = this.state.categorylistdata;
  }
  let loaderimg = '';
  let preview = '';

 
 
  const imagepre = `${categoryURL}/${categorylistdata.image}`;


 
 
  if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
    const loaderimg = `${categoryURL}/${categorylistdata.image}`;
   
    preview = <img className="img_class" src={loaderimg} alt="" />;
  // alert('hh');
  }else{
    preview = <img className="img_class" src={imagepre} alt="" />;
  }
  

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="ProductCategory" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
                </div> 
                <div className="title">
                  <h3>Product Category</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{categorylistdata.name}</p>
                        </div>
                      </div>
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{categorylistdata.description}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Short Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{categorylistdata.short_description}</p>
                        </div>
                      </div>

                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(categorylistdata.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category Icon<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                          {preview}
                        </div>
                      </div>
                    

                      </div>
                      </div>
                      
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default (withRouter(View));