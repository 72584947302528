import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
	geocodeByPlaceId
  }  from 'react-places-autocomplete';
  import MyEditor from '../Membership/MyEditor';
  import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			store_name:'',			
			store_fname:'',
			store_username:'',
			store_contact:'',
			store_email_address:'',
			store_address: '',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			Loading:false,
			address:'',
			categorylist: '',
			selectedCategoryvalue: '',
			selectedCategoryOption: '',
			zipcode: '',
			reviews:'',
			rating:'',
			latitude:'',
			longitude:'',
			selectedYear:'',
			selectedYearvalue:'',
			short_description:'',
			description:'',
			website:'',

		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleCategoryChange = this.handleCategoryChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	onEditorChange(evt) {
		console.log(evt, 'CKE')
		setTimeout(() => {
			this.setState({
				short_description: evt
			});
		}, 1000);
	}
	handleCategoryChange = (selOption) => {
       
		let selCategory = [];
		let selCategoryValue = [];
		selOption.map(item => {
			selCategoryValue.push(item.value);
			selCategory.push({ label: item.label, value: item.value });
		});
		
		this.setState({selectedCategoryOption:selCategory});
		this.setState({  selectedCategoryvalue : selCategoryValue});
 	};

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	categoryListData(postobject=''){
		var qs = require('qs');
		var admin_id = localStorage.getItem("admin_id");
		var loggedas = localStorage.getItem("loggedas");
		var postobject = {
			activePage: 1,
			search_all: '',
			admin_id: admin_id,
			loggedas: loggedas
		};    

		axios.post(apiUrl+"ProductCategory/list",qs.stringify(postobject)).then(res => {
			if(res.data.status === 'success'){
				var categorylist = res.data.categorylist;
				let i=0;
				var categorylists = [];
				for(i; i<categorylist.length; i++){
					categorylists[i] = { value: categorylist[i]['id'], label: categorylist[i]['name'] };
					console.log(categorylist,"categorylists12");
				}
				console.log(categorylists,"categorylists");
				this.setState({  categorylist:categorylists });
			}
		}); 
	  }

	componentDidMount() {
		document.title = PageTitle('Store Add');
		this.categoryListData()
    }
	handleChangeaddress = location => {
		this.setState({ location });
	  };
	  handleSelect = async (location,placeId) => {
     /*    console.log(location,'location')
		geocodeByAddress(location)
		  .then(results => getLatLng(results[0]))
		  .then(latLng => console.log('Success', latLng))
		  .catch(error => console.error('Error', error));

 */
		  const results = await geocodeByAddress(location);
		  const latLng = await getLatLng(results[0]);
		  const [place] = await geocodeByPlaceId(placeId);
		  const { long_name: postalCode = '' } =
			place.address_components.find(c => c.types.includes('postal_code')) || {};
		  this.setState({ location });
		  console.log(postalCode,'postalCode')
		  this.setState({latitude : latLng['lat'],longitude : latLng['lng'] ,zipcode : postalCode })

	  };

        handleFormSubmit = () => {
		
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem('admin_id'),
					accesscode : localStorage.getItem("company_code"),
					store_name: formPayload.store_name,					
					//store_username:formPayload.store_username,
					product_category: formPayload.selectedCategoryvalue,
					store_contact:formPayload.store_contact,
					store_email_address:formPayload.store_email_address,
					//store_address:formPayload.store_address,
					store_status:status,
					location:formPayload.location, 
					website : formPayload.website,
					zipcode : formPayload.zipcode,
					latitude: formPayload.latitude,
					longitude: formPayload.longitude,
					rating:formPayload.rating,
					reviews:formPayload.reviews,
					startedYear: formPayload.selectedYearvalue,
					short_description:formPayload.short_description,
			        description:formPayload.description,
				};
			   axios.post(apiUrl+"store/add",qs.stringify(postObject)).then(res => {
				 
				 if(res.data.status === 'success'){
					$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						this.setState({
							store_name:'',			
							store_fname:'',
							store_username:'',
							store_contact:'',
							store_email_address:'',
							store_address: '',
							selectedvalue: '',
						});
						setTimeout(
						function() {
							$('.success_message').html(this.props.navigate('/store'));
						}
						.bind(this),
						3000
						);
					}else{
						this.setState({Loading:false});
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						
					}
			   }); 
			}
	}
afterSuccess(){
	$('.success_message').html('');
	this.props.navigate('/store');
	
}
	validateForm() {
		const {store_name,website ,selectedCategoryOption, selectedYear,rating, reviews, store_email_address, description,short_description, store_address,store_contact,address } = this.state;
		let errors = {};
      	let formIsValid = true;
	var maxCharacterLimit =20;
		if (!store_name) {
			formIsValid = false;
			$('.errorstore_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(store_name){
			if (store_name.length <= maxCharacterLimit) {
				$('.errorstore_name').html('');
	
			  }else{
				$('.errorstore_name').html('Store name should be less than 20 characters');
	
			  }
			
		}
		if (!website) {
			errors++;
			$('.errorwebsite').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.errorwebsite').html('');
		}
		if (!rating) {
			errors++;
			$('.errorrating').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.errorrating').html('');
		}
		if (!selectedYear) {
			errors++;
			$('.erroryear_of_started').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.erroryear_of_started').html('');
		}

		
		if (!reviews) {
			errors++;
			$('.errorreviews').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.errorreviews').html('');
		}
		if(!description){
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else{
			$('.errordescription').html('');
		}
		if (!short_description) {
			formIsValid = false;
			$('.errorshort_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(short_description){
			$('.errorshort_description').html('');
		}	
		// if (!store_username) {
		// 	formIsValid = false;
		// 	$('.errorstore_username').html('<span class="errorspan">Please fill the field</span>');
		// }else if(store_username){
		// 	if (store_username.length <= maxCharacterLimit) {
		// 		$('.errorstore_username').html('');
	
		// 	  }else{
		// 		$('.errorstore_username').html('Username should be less than 20 characters');
	
		// 	  }
			
		// }		
		
		if (!store_email_address) {
			formIsValid = false;
			
			$('.errorstore_email_address').html('<span class="errorspan">Email is required</span>');
			}else if(store_email_address){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(store_email_address)) 
			{
			  $('.errorstore_email_address').html('');
			}else{
				formIsValid = false;
			  
			$('.errorstore_email_address').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
		

		if (!store_contact) {
			formIsValid = false;
			
			$('.errorstore_contact').html('<span class="errorspan">Contact is required</span>');
		  }else if(store_contact){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				if (pattern.test(store_contact)) {
				$('.errorstore_contact').html('');
				}else if(indiapattern.test(store_contact)){
				$('.errorstore_contact').html('');
				}else{
					formIsValid = false;
				
					 $('.errorstore_contact').html('<span class="errorspan">Please enter valid contact</span>');
				   }
				}
		

		// if (!store_address) {
		// 	formIsValid = false;
		// 	$('.errorstore_address').html('<span class="errorspan">Please fill the field</span>');
		// }else if(store_address){
		// 	$('.errorstore_address').html('');
		// }

		if (selectedCategoryOption.length == 0) {
			formIsValid = false;
			$('.errorproduct_category').html('<span class="errorspan">Please choose atleast one category</span>');
		}else if(selectedCategoryOption){
			$('.errorproduct_category').html('');
		}


		this.setState({
			errors: errors
		});
		return formIsValid;
    }
	handleYearChange = selOption => {
        this.setState({selectedYear:selOption})
			this.setState({  selectedYearvalue : selOption.value});
    };
 
  render() {
	const navigate = this.props.navigate;
  	  const {selectedOption ,selectedYear} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="storelist" />  

	
	<div className="content">	
		<div className="content-wrapper">
				  <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
            </div>
			<div className="title">
				<h4>Add Store</h4>				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Store Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="store_name" onChange={this.handleInputChange} className="form-control" value={this.state.store_name} />
					<div className="errorstore_name"></div>
				</div>
							
				<div className="form-group">					
					<label>Contact<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="store_contact" onChange={this.handleInputChange} className="form-control" value={this.state.store_contact} />
					<div className="errorstore_contact"></div>
				</div>
				<div className="form-group">
									<label>Short Description:<span class="required" style={{ color: "red" }} > </span></label>
									<MyEditor  onEditorChange={this.onEditorChange}></MyEditor>
				</div>
				<div className="errorshort_description"></div>
				<div className="form-group">
						<label>Location:</label>
							<PlacesAutocomplete	value={this.state.location}	onChange={this.handleChangeaddress}	onSelect={this.handleSelect}			>
								{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
								<div>
									<input  type="text" name="location" className="form-control" value={this.state.location}	
									{...getInputProps({
										placeholder: 'Search Places ...',
										className: 'location-search-input',
									})}
									/>
									<div className="autocomplete-dropdown-container">
									{loading && <div>Loading...</div>}
									{suggestions.map(suggestion => {
										const className = suggestion.active
										? 'suggestion-item--active'
										: 'suggestion-item';
										// inline style for demonstration purpose
										const style = suggestion.active
										? { backgroundColor: '#fafafa', cursor: 'pointer' }
										: { backgroundColor: '#ffffff', cursor: 'pointer' };
										return (
										<div
											{...getSuggestionItemProps(suggestion, {
											className,
											style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
										);
									})}
									</div>
								</div>
								)}
							</PlacesAutocomplete>
							<div className="errorlocation"></div>
					    </div>
						
						<div className="form-group">
							<label>Rating:</label>
							<input type="text" name="rating" onChange={this.handleInputChange} className="form-control" value={this.state.rating} autoComplete="off"/>
							<div className="errorrating"></div>
					     </div>
						
						<div className="form-group">
								<label>Year Started:</label>
								<div className={(this.state.selectedYear != "")?'focus-out focused':'focus-out'}>
								<div className='time-row'>
									<Select className="dropdown-container"
										value={selectedYear?selectedYear:''}
										options={lang.common.year_option} 
										onChange={this.handleYearChange.bind(this)}
										/>
									</div>
								</div>
								<div className="erroryear_of_started"></div>
						</div>				
					
					</div>
			
			<div className="form-right">	
			
				<div className="form-group">					
					<label>Product Category<span class="required" style={{ color: "red" }} > * </span> :</label>
					<Select 
					value={this.state.selectedCategoryOption?this.state.selectedCategoryOption:""}
					options={this.state.categorylist} 
					onChange={this.handleCategoryChange.bind(this)}
					isSearchable={true}
					isMulti
					/>
					<div className='errorproduct_category'></div>
	           </div> 
				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="store_email_address" onChange={this.handleInputChange} className="form-control" value={this.state.store_email_address} />
					<div className="errorstore_email_address"></div>
				</div>
				
				{/* <div className="form-group">					
					<label>Description:</label>
						<textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} />
					<div className="errordescription"></div>
				</div> */}
				<div className="form-group">
									<label>Description:<span class="required" style={{ color: "red" }} ></span></label>
									{/* <MyEditor  onEditorChange={this.onEditorChange}></MyEditor> */}
									<CKEditor
										editor={ ClassicEditor }
										data=""
										onReady={ editor => {
											// You can store the "editor" and use when it is needed.
											console.log( 'Editor is ready to use!', editor );
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											this.setState({description: data});
											console.log( data,"data");
										} }
									/>
								</div>
						<div className="errordescription"></div>
				<div className="form-group">					
					<label>Website : </label>
						<input type="text" name='website' onChange={this.handleInputChange} className='form-control' value={this.state.website} />
					<div className="errorwebsite"></div>
				</div>
				<div className="form-group">
						<label>Reviews:</label>
						<input type="text" name="reviews" onChange={this.handleInputChange} className="form-control" value={this.state.reviews} autoComplete="off"/>
						<div className="errorreviews"></div>
					</div>
					
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
					<div className=""></div>
				</div>
			</div>	
			</div>		

			<div className="btn-group export">	
			   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>Loading</span>:<span>Submit</span>
                                        } 
								</button>

			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}



export default withRouter(Add);