import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl} from'../Config/Config';
import MyEditor from '../Membership/MyEditor';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
	geocodeByPlaceId
  }  from 'react-places-autocomplete';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		
	    const StoreId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		this.state = {
			store_name:'',
			store_fname:'',
			store_username:'',
			store_contact:'',
			store_email_address:'',
			store_address: '',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			user_password:'',
			user_confirm_password:'',
			store_id: StoreId,
			Loading:false,
			description:'',
			zipcode: '',
			reviews:'',
			rating:'',
			latitude:'',
			longitude:'',
			selectedCategoryOption:[],
			categorylist: '',
			short_description:'',
			selectedYear:'',
			selectedYearvalue:'',
			yearofstarted:'',
			website:'',
			
		};
		this.handleCategoryChange = this.handleCategoryChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind();
		var qs = require('qs');
		var storeId = this.state.store_id;

		axios.get(apiUrl+"store/listdetail?id="+storeId).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.storelist;
				const storedata = res.data.categories;
				
			if(formdata.store_status === 'Yes'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

			}
			this.setState({store_name:formdata.store_name});			
			this.setState({store_contact: formdata.store_contact});
			this.setState({store_email_address: formdata.store_email_address});
			this.setState({location: formdata.store_address});
			this.setState({store_username: formdata.store_username});
			this.setState({zipcode: formdata.zipcode});
			this.setState({latitude: formdata.latitude});
			this.setState({longitude: formdata.longitude});
			this.setState({selectedCategoryOption:storedata });
			this.setState({short_description:formdata.short_description });
			this.setState({description:formdata.description });
			this.setState({rating:formdata.rating });
			this.setState({reviews:formdata.reviews })
			this.setState({website:formdata.website })
			
			if(formdata.year_of_started !== ''){
				this.setState({yearofstarted:formdata.year_of_started});
				this.setState({selectedYear:{value: formdata.year_of_started, label: formdata.year_of_started}});
			} else{
				this.setState({selectedYear:''});
			}
			}
			
		}); 

		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
        this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);

    }
	handleCategoryChange = (selOption) => {
       
		let selCategory = [];
		let selCategoryValue = [];
		selOption.map(item => {
			selCategoryValue.push(item.value);
			selCategory.push({ label: item.label, value: item.value });
		});
		
		this.setState({selectedCategoryOption:selCategory});
		this.setState({  selectedCategoryvalue : selCategoryValue});
 	};

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	onEditorChange = ( evt ) => {
		var current = this;
		setTimeout(function () {
		current.setState( {
			short_description: evt
		} );
		}.bind(this),1000);
	}
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }
	handleChangeTxt = (item, event) => {  
        this.setState({[item]:event.target.value }); 
    }
	new_pass_show_hide = () => {
		this.setState({
			 new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
			 pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
			});
	}
		
	confim_pass_show_hide = () => {
		this.setState({
			 confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
			 confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
			});
	}   
	categoryListData(postobject=''){
		var qs = require('qs');
		var admin_id = localStorage.getItem("admin_id");
		var loggedas = localStorage.getItem("loggedas");
		var postobject = {
			activePage: 1,
			search_all: '',
			admin_id: admin_id,
			loggedas: loggedas
		};    

		axios.post(apiUrl+"ProductCategory/list",qs.stringify(postobject)).then(res => {
			if(res.data.status === 'success'){
				var categorylist = res.data.categorylist;
				let i=0;
				var categorylists = [];
				for(i; i<categorylist.length; i++){
					categorylists[i] = { value: categorylist[i]['id'], label: categorylist[i]['name'] };
					
				}
				console.log(categorylists,"categorylists");
				this.setState({  categorylist:categorylists });
			}
		}); 
	  }
	componentDidMount() {
		document.title = PageTitle('Store Edit');
		this.categoryListData()
    }

        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					store_id : formPayload.store_id,
					store_name: formPayload.store_name,					
					store_username:formPayload.store_username,
					store_contact:formPayload.store_contact,
					store_email_address:formPayload.store_email_address,
					store_address: formPayload.location,
					password:formPayload.user_password,
					store_status:status,
					latitude: formPayload.latitude,
					longitude: formPayload.longitude,
					zipcode : formPayload.zipcode,
					product_category: formPayload.selectedCategoryvalue,
					rating:formPayload.rating,
					reviews:formPayload.reviews,
					startedYear: formPayload.selectedYearvalue?formPayload.selectedYearvalue: formPayload.yearofstarted,
					short_description:formPayload.short_description,
			        description:formPayload.description,
					website:formPayload.website,
					
				};
				//console.log(postObject);

				axios.post(apiUrl+"store/edit",qs.stringify(postObject)).then(res => {
					
					if(res.data.status === "success"){
						this.setState({ Loading: false });
						const formpayload = res.data.storelist;
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
								this.props.navigate('/store');
							}
							.bind(this),
							3000
							);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {store_name, website,location,store_username, selectedYear, rating, reviews, description,short_description, selectedCategoryOption ,store_email_address,store_contact,user_password,user_confirm_password} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		  var maxCharacterLimit =20;
		  if (!store_name) {
			  formIsValid = false;
			  $('.errorstore_name').html('<span class="errorspan">Please fill the field</span>');
		  }else if(store_name){
			  if (store_name.length <= maxCharacterLimit) {
				  $('.errorstore_name').html('');
	  
				}else{
				  $('.errorstore_name').html('Store name should be less than 20 characters');
	  
				}
			  
		  }
		  if (!location) {
			formIsValid = false;
			$('.errorlocation').html('<span class="errorspan">Please fill the field</span>');
		  }
		  if (!store_username) {
			  formIsValid = false;
			  $('.errorstore_username').html('<span class="errorspan">Please fill the field</span>');
		  }else if(store_username){
			  if (store_username.length <= maxCharacterLimit) {
				  $('.errorstore_username').html('');
	  
				}else{
				  $('.errorstore_username').html('Username should be less than 20 characters');
	  
				}
			  
		  }		
		if (selectedCategoryOption.length == 0) {
			formIsValid = false;
			$('.errorproduct_category').html('<span class="errorspan">Please choose atleast one category</span>');
		}else if(selectedCategoryOption){
			$('.errorproduct_category').html('');
		}
		  if (!store_email_address) {
			  formIsValid = false;
			  
			  $('.errorstore_email_address').html('<span class="errorspan">Email is required</span>');
			  }else if(store_email_address){
				
			  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			  if (reg.test(store_email_address)) 
			  {
				
				$('.errorstore_email_address').html('');
			  }else{
				
				  formIsValid = false;
				
			  $('.errorstore_email_address').html('<span class="errorspan">Please enter valid email address</span>');
			  }
				
			  
			  }
		  
			if (!selectedYear) {
				errors++;
				formIsValid = false;
				$('.erroryear_of_started').html('<span class="errorspan">Please fill the field</span>');
			} else{
				$('.erroryear_of_started').html('');
			} 
			if (!website) {
				errors++;
				formIsValid = false;
				$('.errorwebsite').html('<span class="errorspan">Please fill the field</span>');
			} else{
				$('.errorwebsite').html('');
			}   
			
		  if (!store_contact) {
			  formIsValid = false;
			  
			  $('.errorstore_contact').html('<span class="errorspan">Contact is required</span>');
			}else if(store_contact){	
			   var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			   var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				  if (pattern.test(store_contact)) {
				  $('.errorstore_contact').html('');
				  }else if(indiapattern.test(store_contact)){
				  $('.errorstore_contact').html('');
				  }else{
					  formIsValid = false;
				  
					   $('.errorstore_contact').html('<span class="errorspan">Please enter valid contact</span>');
					 }
				  }
  
  
        if(user_password !== user_confirm_password){
            formIsValid = false;
             $('.usercpswd').html('<span class="errorspan">Password does not match</span>');
        }
		if (!rating) {
			formIsValid = false;
			errors++;
			$('.errorrating').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.errorrating').html('');
		}
		if (!reviews) {
			formIsValid = false;
			errors++;
			$('.errorreviews').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.errorreviews').html('');
		}
		if(!description){
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else{
			$('.errordescription').html('');
		}
		if (!short_description) {
			formIsValid = false;
			$('.errorshort_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(short_description){
			$('.errorshort_description').html('');
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
    }
	handleChangeaddress = location => {
		this.setState({ location });
	  };
	  handleSelect = async (location,placeId) => {
			 const results = await geocodeByAddress(location);
			 const latLng = await getLatLng(results[0]);
			 const [place] = await geocodeByPlaceId(placeId);
			 const { long_name: postalCode = '' } =
			   place.address_components.find(c => c.types.includes('postal_code')) || {};
			 this.setState({ location });
			 console.log(postalCode,'postalCode')
			 this.setState({latitude : latLng['lat'],longitude : latLng['lng'] ,zipcode : postalCode })
   
		 };
	
    componentWillReceiveProps(Props){
    	if(Props.companyedit !== this.props.companyedit){
    		if(Object.keys(Props.companyedit).length > 0){
				if(Props.companyedit[0].status === "success"){
    					this.setState({ Loading: false });

    			const formpayload = Props.companyedit[0].storelist;
				if(formpayload.admin_status === 'Yes'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
				this.setState({store_name:formpayload.store_name});
				
				//this.setState({c_company_id: formpayload.c_company_id});
				this.setState({store_contact: formpayload.store_contact});
				this.setState({store_email_address: formpayload.store_email_address});
				this.setState({store_username: formpayload.store_username});
               	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.companyedit[0].message+'</h3></div>');


    					setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/store'));
						}
						.bind(this),
						3000
						);
    		}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.companyedit[0].message+'</h3></div>');
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
			}
    	}
		
    }
	handleYearChange = selOption => {
        this.setState({selectedYear:selOption})
			this.setState({  selectedYearvalue : selOption.value});
    };
 
  render() {
	const navigate = this.props.navigate;
  	  const {selectedOption ,selectedYear} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="storelist" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="">
				
			<div class="content-wrapper-before"></div>
			<div className="car-listing-wrap leaa-admin-head card">
				<div className="success_message"></div>
				<div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
            	</div>
					<div className="title">
						<h4>Edit Details</h4>
					</div>
					<form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
					<div className="form-row">
					<div className="form-left">
						<div className="form-group">
							<label>Store Name<span class="required" style={{ color: "red" }} >* </span></label>
							<input type="text" name="store_name" onChange={this.handleInputChange} className="form-control" value={this.state.store_name} />
							<div className="errorstore_name"></div>
						</div>
						<div className="form-group">					
							<label>Contact<span class="required" style={{ color: "red" }} >* </span></label>
								<input type="text" name="store_contact" onChange={this.handleInputChange} className="form-control" value={this.state.store_contact} />
							<div className="errorstore_contact"></div>
						</div>
						{/* <div className="form-group">					
							<label>Short Description<span class="required" style={{ color: "red" }} > * </span> :</label>
								<textarea type="text" name="short_description" onChange={this.handleInputChange} className="form-control" value={this.state.short_description}  />
							<div className="errorshort_description"></div>
						</div> */}
						<div className="form-group">
									<label>Short Description:<span class="required" style={{ color: "red" }} > </span></label>
									<MyEditor initialData={this.state.short_description} onEditorChange={this.onEditorChange}></MyEditor>
						</div>
						<div className="errorshort_description"></div>
						<div className="form-group">
					<label>Location:</label>
							<PlacesAutocomplete	value={this.state.location}	onChange={this.handleChangeaddress}	onSelect={this.handleSelect}			>
								{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
								<div>
									<input  type="text" name="location" className="form-control" value={this.state.location}	
									{...getInputProps({
										placeholder: 'Search Places ...',
										className: 'location-search-input',
									})}
									/>
									<div className="autocomplete-dropdown-container">
									{loading && <div>Loading...</div>}
									{suggestions.map(suggestion => {
										const className = suggestion.active
										? 'suggestion-item--active'
										: 'suggestion-item';
										// inline style for demonstration purpose
										const style = suggestion.active
										? { backgroundColor: '#fafafa', cursor: 'pointer' }
										: { backgroundColor: '#ffffff', cursor: 'pointer' };
										return (
										<div
											{...getSuggestionItemProps(suggestion, {
											className,
											style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
										);
									})}
									</div>
								</div>
								)}
							</PlacesAutocomplete>
							<div className="errorlocation"></div>
					    </div>
						
						<div className="form-group">
							<label>Rating:</label>
							<input type="text" name="rating" onChange={this.handleInputChange} className="form-control" value={this.state.rating} autoComplete="off"/>
							<div className="errorrating"></div>
						</div>
						<div className="form-group">
								<label>Year Started:</label>
								<div className={(this.state.selectedYear != "")?'focus-out focused':'focus-out'}>
								<div className='time-row'>
									<Select className="dropdown-container"
										value={selectedYear?selectedYear:''}
										options={lang.common.year_option} 
										onChange={this.handleYearChange.bind(this)}
										/>
									</div>
								</div>
								<div className="erroryear_of_started"></div>
						</div>
						
						<div className="form-group eye-pad">
                            <label>Reset Password</label>
                            <input type={this.state.new_pass_type}  name="user_password" placeholder="Enter new password" className="form-control" value={this.state.user_password} onChange={this.handleChangeTxt.bind(this, 'user_password')}/>
                            <span toggle="#password-field" className={'shown ' + this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
                            <div className="userpswd"></div>
                        </div>
                       
					</div>	

					<div className="form-right">
					<div className="form-group">					
					   <label>Product Category<span class="required" style={{ color: "red" }} > * </span> :</label>
						<Select 
						value={this.state.selectedCategoryOption?this.state.selectedCategoryOption:""}
						options={this.state.categorylist} 
						onChange={this.handleCategoryChange.bind(this)}
						isSearchable={true}
						isMulti
					/>
					<div className='errorproduct_category'></div>
	           </div> 
				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} >* </span></label>
						<input type="text" name="store_email_address" onChange={this.handleInputChange} className="form-control" value={this.state.store_email_address} />
					<div className="errorstore_email_address"></div>
				</div>
				{/* <div className="form-group">					
					<label>Description:</label>
						<textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} />
					
				</div> */}
				<div className="form-group">
									<label>Description<span class="required" style={{ color: "red" }} ></span></label>
									{/* <MyEditor  onEditorChange={this.onEditorChange}></MyEditor> */}
									<CKEditor
										editor={ ClassicEditor }
										data={this.state.description}
										onReady={ editor => {
											// You can store the "editor" and use when it is needed.
											console.log( 'Editor is ready to use!', editor );
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											this.setState({description: data});
											console.log( data,"data");
										} }
									/>
				</div>
				
				<div className="errordescription"></div>
				<div className="form-group">					
							<label>Website : </label>
								<input type="text" name='website' onChange={this.handleInputChange} className='form-control' value={this.state.website} />
				<div className="errorwebsite"></div>
				</div>
				<div className="form-group">
						<label>Reviews:</label>
						<input type="text" name="reviews" onChange={this.handleInputChange} className="form-control" value={this.state.reviews} autoComplete="off"/>
						<div className="errorreviews"></div>
				</div>
						<div className="form-group">					
							<label>Status<span class="required" style={{ color: "red" }} >* </span></label>
								<Select 
								value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
									options={lang.common.status_option} 
									onChange={this.handleChange}
									/>
							<div className="errorstore_email_address"></div>
						</div>
						<div className="form-group eye-pad">
                            <label>Confirm Password</label>
                            <input type={this.state.confrim_pass_type} name="user_confirm_password" id="user_confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.user_confirm_password} onChange={this.handleChangeTxt.bind(this, 'user_confirm_password')}/>
                            <span toggle="#password-field" className={'shown ' + this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
                            <div className="usercpswd"></div>
                        </div>
					</div>
					</div>		

					<div className="btn-group export">	
						<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
												<span className="load-data"></span> 
												}Submit
						</button>
					</div>
					</form>
				</div>	
			</div>			
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default withRouter(Edit);