import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Table
} from "react-bootstrap"; 

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import {apiUrl} from'../Config/Config';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Select from 'react-select';
import { lang } from '../Helpers/lang';

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      faqlist:'',
      domainlist:[],
      selectedCateOption:'',
      selectedCatevalue:'',
      faqIDs:'',
      activePage:1,
      sno_index:0,
      category:'',
      selectedStatus:'',
      selectid:'',
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
      this.listloadedData()
   }

   listloadedData(postobject='') {
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");

      if(postobject!=''){
        var postobject = postobject;
      }else{
        var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id,
        loggedas: loggedas
        };  
      }

      axios.post(apiUrl+"faq/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){         
            this.setState({  faqlist: res.data.faqlist, totalRecord: res.data.records_count}, function() {            
              this.faqlist();
            });  
        }else{         
          this.setState({  faqlist: '', totalRecord: 0}, function() {            
            this.faqlist();
          });
        }
      }); 
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});
     var sno_index = pageNumber - 1;
     this.setState({sno_index: sno_index});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
     
    //  this.props.getfaqlist(qs.stringify(postobject))
    this.listloadedData(postobject)

   }

   handleChange = (selOption) => {       	
    this.setState({selectedCateOption:selOption})
    this.setState({  selectedCatevalue : selOption.value},function(){
      this.handleFormSubmit()
    });
 };

 handleInputChange = (event) => {
  console.log(event, 'eve')
  const {name, value} = event.target; 
  var postObject = {             
    activePage   : this.state.activePage,
    search_all : value,
    admin_id: localStorage.getItem('admin_id')           
  };
  console.log(postObject, 'postobject123')
  this.setState({
    [name]: value,
    // category: value
  },function(){
    this.handleFormSubmit()
  });
}

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
  console.log(formPayload, 'formPayload')
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    category : formPayload.selectedCatevalue,
    admin_id: admin_id,
    loggedas: loggedas         
  };
 
  this.listloadedData(postObject)

} 


activefaq(){
  let stat = ''
  if(this.state.selectedStatus ==='inactive'){
      stat = "I";
    }else{
      stat = "A";
    }
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :this.state.selectid,
          status: stat
        };
        console.log(postObject, 'po123455')
        if(stat == "A"){
          axios.post(apiUrl+"faq/checkBeforeChange",qs.stringify(postObject)).then(res => {
            if(res.data.status === 'success'){
              this.change_status(postObject);
            }else{
              $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
              setTimeout(
              function() {
                $('.success_message').html('');
              }
              .bind(this),
              3000
              );
            }
          });
        }else{
          this.change_status(postObject);
        }
      
}

confirmPopup = (id, status) => {
  var status = status;
  if(status == 'inactive'){
    status = "active";
  }else{
    status = "inactive";
  }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.activefaq();
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}

change_status(postObject){
  var qs = require('qs');
  axios.post(apiUrl+"faq/change_status",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){
      this.listloadedData()
     $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
    
    }
  }); 
}

checkRow = (id) => {
  var faqidArr = [...this.state.faqIDs];
  const index = faqidArr.findIndex(faq_list => faq_list === id);
  if(index > -1) {
    faqidArr = [...faqidArr.slice(0, index), ...faqidArr.slice(index + 1)]
  } else {
    faqidArr.push(id);   
  }
  this.setState({faqIDs: faqidArr});
}


faqlist() {
  let catImg = '';
  let preview = '';
    var faqlist = this.state.faqlist;
    var sno_index = this.state.sno_index;
    if (faqlist != "undefined" && faqlist != null) {
    
      if (faqlist.length > 0) {

        if (Object.keys(faqlist).length > 0) {
          const helperlistDetails = faqlist.map(
            (faqlist, Index) => {
              if(sno_index > 0){ Index = (sno_index * 10) + Index; } 
              let sno = Index+1;
              

               if(faqlist.status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }

              var answer = faqlist.faq_answer;
             
              return (
                <tr key={faqlist.id}>
                <td><input type='checkbox' onClick={this.checkRow.bind(this,faqlist.id)} /></td>
      					{/* <td width="10px">{sno}</td> */}
      					<td>{faqlist.faq_question}</td>
      					<td>{answer.substring(0,50)}</td>
      					<td>{faqlist.cate_name}</td>
                <td width="30px">{status}</td>
                <td className="actiongroup" >
                    
                    
                     {/* <Link to={{ pathname: '/faq-view/'+faqlist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link> */}
                    <Link to={{ pathname: '/faq-edit/'+faqlist.id}} className="" title="Edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                    {(status == 'Active'?<Link onClick={this.confirmPopup.bind(this, faqlist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, faqlist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}      

                   

                     {/* <Link onClick={this.deletememebership.bind(this,faqlist.id)} className="" title="Duplicate"><i className="ft-trash-2" aria-hidden="true"></i></Link> */}
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  

  export = () => {
    // console.log('aifeedidArr', this.state.faqIDs);
    var faqIDs = this.state.faqIDs;
    if(faqIDs.length > 0){
      var qs = require('qs');
  
      var postObject = {   
          ids: faqIDs   
      };
      // console.log(postObject, 'postObject'); return;
      axios.post(apiUrl+"faq/export",qs.stringify(postObject)).then(res => {
        // console.log(res, 'ai_res');
        if(res.data.status !== ''){
            $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
                  
            if(res.data.status == 'success'){
              var url = res.data.url;
              var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
              window.open(cleanurl, '_blank', 'noreferrer');
              window.location.reload();
            }
  
            setTimeout(
            function() {
              $('.success_message').html('');
            }
            .bind(this),
            3000
            );
        }
      }); 
    }else{
      alert('Please select atleast one');
    }
  }

  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} FAQ?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="Faq" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Faq</h3>
                </div>
               

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div class="input_search">
                    <div className="search-inner search_input">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     
                    <div className="erroremail"></div>
                    </div>
                    <div className="search-inner search_select">
                    <Select name="category" options={lang.common.categories_option1} 
                          // onChange={this.handleInputChange}
                            onChange={this.handleChange.bind(this)}
                            />
                    </div>
                    {/* <div class="search-inner search_btn">
                    <button className="" type="submit">Submit</button>

                    </div> */}
                    </div>
                 
                 </form>
                </div>
                 <div className="add_button">  
                    <a className="" href='/faq-add'>Add</a> &nbsp;|&nbsp;
                    <a className="" href='/faq-import'>Import <i className="import-icon icon" aria-hidden="true"></i></a> &nbsp;|&nbsp;
                    <a className="" onClick={this.export}>Export <i className="export-icon icon" aria-hidden="true"></i></a>
                    <a class="exportcsv_url" href=""></a>
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th></th>
                  {/* <th width="10px">S.No</th> */}
			            <th>Faq Question</th>
			            <th>Faq Answer</th>
			            <th>Category</th>
                  <th width="30px">Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.faqlist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}
export default List;