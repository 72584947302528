import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL,mediaUrl} from'../Config/Config';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import MyEditor from '../Membership/MyEditor';
import { useParams, useNavigate } from 'react-router-dom';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Addimageinputsimple from './Addimageinputsimple';
import { Link } from "react-router-dom";
import {
    Row,
    Col,
    Modal,
    Table,
    OverlayTrigger,
    ButtonToolbar,
    Tooltip,
    Button
  } from "react-bootstrap";
const withRouter = WrappedComponent => props => {
	const params = useParams();
   
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Addconfig extends Component {

	fileObj = [];
    fileArray = [];
    imageArray = [];
	fileObj1 = [];
	tryoutfileArray = [];
	tryimageArray = [];

	fileObj2 = [];
	configimageArray=[];
	configfileArray=[];
	constructor(props)
	{
		// CheckAuth();
		super();	
        const configproductId = (props && props.params && typeof props.params.ID !== 'undefined') ? props.params.ID : '';
		this.state = {
            configproductId: configproductId,
			name:'',
			product_code: '',
			price: '',
			qty:'',
			short_description: '',
			description: '',
			product_gallery_image: '',
			tryout_gallery_image: '',
			image_preview:'',
			image_name:'',
			image:'',
			fileExtension: '',
			tryout_image_preview:'',
			tryout_image_name:'',
			tryout_image:'',
			tryout_fileExtension: '',
			trialon: 'main',
			
			configtrialon:'main',
			rows: [], 
			config_gallery_image:'',
            tryoutdata:[],
            data_status:'',
            trial_on:'',
			configproductimglist:'',
			configtryoutimglist: '',
			product_name:'',
			
		};
        axios.get(apiUrl+"configureproduct/listtryoutdetail?id="+this.state.configproductId).then(res => {
			
			console.log(res.data.configtryoutlistview,'tesingrespone');
            this.setState({tryoutdata: res.data.configtryoutlistview,data_status: res.data.status});
			if(res.data.configtryoutlistview.laptype === 'overlap'){
				this.setState({selectedOption:{value: 'overlap', label: 'Overlap'}});
			}else{
				this.setState({selectedOption:{value: 'underlap', label: 'Underlap'}});
			}
		});
		this.getcofigimages();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
    }
	componentDidMount() {
		setTimeout(() => {
		  this.getcofigimages();
		  this.gettryoutimage();
		  this.configfileArray = [];
		  this.setState({ config_gallery_image: '' });
		  $('.success_message').empty();
		}, 2000);
	  }
	
    gettryoutimage =(trialon)=> {

        axios.get(apiUrl+"configureproduct/listtryoutdetail?id="+this.state.configproductId).then(res => {
			console.log(res.data.configtryoutlistview.trial_on,'tesingrespone');
            this.setState({tryoutdata: res.data.configtryoutlistview,data_status: res.data.status,trial_on:res.data.configtryoutlistview.trial_on});
			if(res.data.configtryoutlistview.laptype === 'overlap'){
				this.setState({selectedOption:{value: 'overlap', label: 'Overlap'}});
			}else{
				this.setState({selectedOption:{value: 'underlap', label: 'Underlap'}});
			}
		});
    }
	getcofigimages =()=> {

		var qs = require('qs');
		var postObject = {             
		  id: this.state.configproductId
		};
	   
		axios.post(apiUrl+"configureproduct/view",qs.stringify(postObject)).then(res => {
		  if(res.data.status === 'success'){
			this.setState({ configproductimglist: res.data.configproductimglistview, configtryoutimglist: res.data.configtryoutlistview ,productdetail:res.data.configproductlistview,data_status: res.data.status});
		  }
		});  
    }
	uploadMultipleFiles(arg,e){
		
		if (!e || !e.target || !e.target.files) {
			console.error("Invalid event or files not found.");
			return;
		  }
		
		if(arg=='product'){
			this.fileObj = [];
			this.fileObj.push(e.target.files)
			for (let i = 0; i < this.fileObj[0].length; i++) {
				this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
				this.imageArray.push(this.fileObj[0][i])
			}
			console.log(this.imageArray,"this.imageArray");
			this.setState({ product_gallery_image: this.imageArray })
		} else if (arg=='configtryout'){
			this.fileObj2 = [];
			this.fileObj2.push(e.target.files)
			console.log(this.fileObj2,'imagesttttttttt');
			for (let i = 0; i < this.fileObj2[0].length; i++) {
				
				this.configfileArray.push(URL.createObjectURL(this.fileObj2[0][i]))
				this.configimageArray.push(this.fileObj2[0][i])
				
				this.setState({ config_gallery_image: this.configimageArray })
				console.log(this.configimageArray,'images');
			}
		
		}
		
		// else{
		// 	this.fileObj1 = [];
		// 	this.fileObj1.push(e.target.files)
		// 	for (let i = 0; i < this.fileObj1[0].length; i++) {
		// 		this.tryoutfileArray.push(URL.createObjectURL(this.fileObj1[0][i]))
		// 		this.tryimageArray.push(this.fileObj1[0][i])
		// 	}
			
		// 	this.setState({ tryout_gallery_image: this.tryimageArray })
		// }	
	}
	uploadMultipleConfigFiles(arg,e){
		let tryimageArray= e;
		console.log(tryimageArray,'images')
		this.fileObj1 = [];
			this.fileObj1.push(tryimageArray)
			  for (let i = 0; i < this.fileObj1[0].length; i++) {
						this.tryoutfileArray.push(URL.createObjectURL(this.fileObj1[0][i]))
						this.tryimageArray.push(this.fileObj1[0][i])
			  }	
			this.setState({ tryout_gallery_image: this.tryimageArray })
	}
	

	removeImagebyindex = (indexvalue) => {
		var list = [...this.imageArray];
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);
	
		for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
		}
	
		this.setState({ product_gallery_image: this.imageArray })
		
	}
	removeImageConfigbyindex = (indexvalue) => {
	
		var list = [...this.configimageArray];
		this.configimageArray = [];
		this.configfileArray = [];
		list.splice(indexvalue, 1);
	
		for (let i = 0; i < list.length; i++) {
		this.configimageArray.push(list[i]);
		this.configfileArray.push(URL.createObjectURL(list[i]));
		}
	
		this.setState({ config_gallery_image: this.imageArray })
		
	}
	removeTryoutImagebyindex = (indexvalue) => {
		var trylist = [...this.tryimageArray];
		this.tryimageArray = [];
		this.tryoutfileArray = [];
		trylist.splice(indexvalue, 1);

		for (let i = 0; i < trylist.length; i++) {
		this.tryimageArray.push(trylist[i]);
		this.tryoutfileArray.push(URL.createObjectURL(trylist[i]));
		}

		this.setState({ tryout_gallery_image: this.tryimageArray })
	}

  	onEditorChange( evt ) {
		console.log(evt, 'CKE')
		setTimeout(function () {
		this.setState( {
			short_description: evt
		} );
		}.bind(this),1000);
	}

	onSelcectImage(arg,event){
		
		if(arg == 'product'){
			let reader = new FileReader();
			const file = event.target.files[0];
			const extension = file.name.split('.').pop();
			reader.onloadend = () => {
				this.setState({
				image_name: file.name,
				image_preview: reader.result,
				image: file,
				fileExtension:extension,
				
				});
			};
			reader.readAsDataURL(file);
		}else{
			let tryoutreader = new FileReader();
			const tryoutfile = event.target.files[0];
			const extension1 = tryoutfile.name.split('.').pop();
			tryoutreader.onloadend = () => {
				this.setState({
					config_gallery_image: tryoutfile.name,
					tryout_image_preview: tryoutreader.result,
					tryout_image: tryoutfile,
					tryout_fileExtension:extension1,
				});
			};
			tryoutreader.readAsDataURL(tryoutfile);
		}
	}

   	// handleInputChange(event) {

      
    //   const {name, value} = event.target;      
    //   this.setState({
    //       [name]: value
    //     });
    //     console.log(value,'inpuname')
    //     if(value ==='element'){
	// 		// console.log('this is working')
	// 		// this.setState({
	// 		// 	config_gallery_image: ''
	// 		//   });
	// 		//   this.configfileArray = [];
    //         this.gettryoutimage(value)
    //     } else if(value ==='main') {

    //         this.gettryoutimage(value)
    //     }
  	// }
	handleInputChange = (event) => {
  const { name, value, type, checked } = event.target;
  console.log(value,'test')
  const newValue = type === 'checkbox' ? (checked ? value : 'main') : value;

  this.setState({
    [name]: newValue
  });

  if (newValue === 'element') {
    this.gettryoutimage(value);
  } else if (newValue === 'main') {
    this.gettryoutimage(value);
  }
}




    
	handleRowChange = (rowIndex, updatedRow) => {
        
		this.setState((prevState) => {
			const updatedRows = [...prevState.rows];
			updatedRows[rowIndex] = updatedRow;
			return { rows: updatedRows };
		});
		
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.state.shouldRerender !== prevState.shouldRerender && this.state.shouldRerender) {
			// Reset the shouldRerender state to false
			this.setState({
				shouldRerender: false
			});
			this.forceUpdate();
			
		}
	}
  	handleSubmit = () => {
		if(this.validateForm()){
			const payload = this.state;
			var qs = require('qs');
			var status = '';

			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			}
			let formData = new FormData();
			var imagedata = payload.product_gallery_image;
			for (var i in imagedata) {
				formData.append('images[]',imagedata[i])
			}

			var configimagedata = payload.config_gallery_image;
			for (var i in configimagedata) {
				formData.append('configimages[]',configimagedata[i])
			}
			var tryimagedata = payload.tryout_gallery_image;
			for (var i in tryimagedata) {
				formData.append('tryoutimages[]',tryimagedata[i])
			}
			console.log(payload,'allvalus');
           
			if(payload.selectedvalue === ''){
				status =payload.selectedOption.value;
			}else{
				status = payload.selectedvalue;
			}
			

			console.log(payload.rows,"newformdata");
			const rowsJson = JSON.stringify(payload.rows);
			payload.rows.forEach((row, index) => {
				row.images.forEach((image, i) => {
					formData.append(`images[${index}][${i}]`, image);
				});
			});
			formData.append('admin_id',localStorage.getItem("admin_id"))
            formData.append('configproductId',payload.configproductId)
            
			formData.append('category',payload.category)
			formData.append('lappriority',payload.lappriority)
			formData.append('laptype',payload.laptype)
			formData.append('xaxis',payload.xaxis)
			formData.append('yaxis',payload.yaxis)
			//formData.append('tryout_image',payload.tryout_image_name)
			formData.append('trialon',payload.trialon)
			formData.append('rowvalue',rowsJson)
			
           
          
           
           
			//console.log(rowsJson,"payloadsssssssssssssssss");
			console.log(payload.rows,"formData");


			axios.post(apiUrl+"configureproduct/adddetails",formData,config).then(res => {
				if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					this.setState({
						shouldRerender: true
					});	
					setTimeout(
							function() {
								this.getcofigimages();
								this.gettryoutimage();
								this.configfileArray = [];
								this.setState({config_gallery_image:''});
								window.location.reload();
								$('.success_message').empty();
							}
							.bind(this),
							2000
							);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		}
		
	}

	validateForm() {
		const {name,product_code,price,image,product_gallery_image,tryout_image,tryout_gallery_image,qty ,config_gallery_image} = this.state;
		const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
		let errors = {};
      	let formIsValid = true;

		let maxSize = 9000000;//1 mb
		console.log(this.state.trialon ,'trion_onevali')
		if(this.state.trialon === 'main') {
			if (!config_gallery_image) {
				formIsValid = false;
				$('.errorconfig_image').html('<span class="errorspan">Please fill the field</span>');
			}else if(config_gallery_image){
				$('.errorconfig_image').html('');
			}
		}
		
		// if (!config_gallery_image) {
		// 	errors++;
		// 	$('.errorconfig_image').html('<span class="errorspan">Please fill the field</span>');
		// }else if(config_gallery_image.length > 10){
				  
		// 	errors++;
		// 	$('.errorconfig_image').html('<span class="errorspan">You can only upload up to 10 images</span>');
				   
		// }else{
			
		// 		$('.errorconfig_image').html('');
		// }

		// if (!tryout_gallery_image) {
		// 	errors++;
		// 	$('.errortryout_image').html('<span class="errorspan">Please fill the field</span>');
		// }else if(tryout_gallery_image.length > 10){
				  
		// 	errors++;
		// 	$('.errortryout_image').html('<span class="errorspan">You can only upload up to 10 images</span>');
				   
		// }else{
		// 	for (let i = 0; i < tryout_gallery_image.length; i++) 
		// 	{
		// 		const file1 = tryout_gallery_image[i];
		// 		const filename1 = file1.name;
		// 		console.log(filename1,"filename")
		// 		if (file1.size > maxSize) {
		// 			errors++;
		// 		$('.errortryout_image').html('<span class="errorspan">'+filename1+'File is too large. Max size is 1MB</span>');
		// 		continue;
		// 		}
		// 		if (!allowedTypes.includes(file1.type)) {
		// 			errors++;
		// 		$('.errortryout_image').html('<span class="errorspan"> '+filename1+' file type is Invalid.</span>');
		// 		continue;
		// 		}
		// 	}
		// }

      	// if(!name){
        // 	formIsValid = false;
        // 	$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		// }else{
			
		// 	$('.errorname').html('');
		// }

		// if(!product_code){
        // 	formIsValid = false;
        // 	$('.errorproductcode').html('<span class="errorspan">Please fill the field</span>');
		// }else{
			
		// 	$('.errorproductcode').html('');
		// }

		// if(!price){
        // 	formIsValid = false;
        // 	$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
		// }else{
			
		// 	$('.errorprice').html('');
		// }

		// if (!image) {
		// 	formIsValid = false;
		// 	$('.errorimage').html('<span class="errorspan">Please fill the field</span>');
		// }else if(image){
		// 	$('.errorimage').html('');
		// }

		// if (!tryout_image) {
		// 	formIsValid = false;
		// 	$('.errortryoutimage').html('<span class="errorspan">Please fill the field</span>');
		// }else if(image){
		// 	$('.errortryoutimage').html('');
		// }

		// if(!qty){
        // 	formIsValid = false;
        // 	$('.errorqty').html('<span class="errorspan">Please fill the field</span>');
		// }else{
			
		// 	$('.errorqty').html('');
		// }
		
		this.setState({
			errors: errors
		});
		return formIsValid;
    }
	handleChangeFromChild = (data) => {
		console.log(data.rows, 'alldata');
		const { rows } = this.state;
		const updatedRows = data.rows.filter((row) => !rows.some((r) => r.category === row.category && r.lappriority === row.lappriority && r.xaxis === row.xaxis && r.yaxis === row.yaxis && r.laptype === row.laptype));
	
		this.setState({
			rows: [...rows, ...updatedRows]
		});
	}


	productlist() {
		var productlist = this.state.productdetail;

		var data_status = this.state.data_status;
		if (productlist != "undefined" && productlist != null) {
		  
		  if (data_status == "success") {
  
			 if (Object.keys(productlist).length > 0) {
			  const listDetails = productlist.map(
				(productlist, Index) => {
				  let sno = Index+1;
				 
  
				  
				  const images = [
					productlist.image,
					  
				  ];
				  const imageList = images.map((image, i) => (
					  <img
						  key={i}
						  className="config-list"
						  src={mediaUrl + productlist.image_path + image}
						  width={200}
						  height={200}
						  alt={`Image ${i+1}`}
					  />
				  ));
				  
				 
					  return (
						<div>
							 <p><strong>Product Name: </strong>{productlist.product_name}</p>
							 <p><strong>Product Code: </strong>{productlist.product_code}</p>
							 <p>{imageList}</p>
						</div>
						);
				 
				  
				}
			  );
			  return listDetails;
			}
			else {
			  return (
				<tr>
				  <td colspan="11" className="v-align-nr">
					No Result
				  </td>
				</tr>
			  );
			}
		  } 
		  
		}
	}
    configproductlist() {
        let catImg = '';
        let preview = '';
          var configproductlist = this.state.tryoutdata;
          console.log(configproductlist,'testingconfigproduct')
          var data_status = this.state.data_status;
		  if (Array.isArray(configproductlist) && configproductlist.length > 0) {
            
            if (data_status == "success") {
    
               if (Object.keys(configproductlist).length > 0) {
                const listDetails = configproductlist.map(
                  (configproductlist, Index) => {
                    let sno = Index+1;
                    var color_code;
    
                    if(configproductlist.laptype == '"overlap"'){
                      var laptype = '"Overlap"';
                    }else{
                        laptype = 'Underlap';
                    }
                    const images = [
                        configproductlist.tryout_image,
                        
                    ];
                    const imageList = images.map((image, i) => (
                        <img
                            key={i}
                            className="config-list"
                            src={mediaUrl + configproductlist.tryout_image_path + image}
                            width={200}
                            height={200}
                            alt={`Image ${i+1}`}
                        />
                    ));
                    
                    // if(this.state.trialon === 'main') {
					// 	return (
                    //     		  <tr key={configproductlist.id}>
                    //               <td>{sno}</td>
                    //               <td>{imageList !== undefined ? imageList : "N/A"}</td>
                    //     </tr>
					// 	  );
                    // } else {
                        return (
                            <tr key={configproductlist.id}>
                            <td>{sno}</td>
                            <td>{configproductlist.element_category !== undefined ? configproductlist.element_category : "N/A"}</td>
							<td>{imageList !== undefined ? imageList : "N/A"}</td>
                            <td>{configproductlist.x_axis !== undefined ? configproductlist.x_axis : "N/A"}</td>
                            <td>{configproductlist.y_axis !== undefined ? configproductlist.y_axis : "N/A"}</td>
							<td>{configproductlist.lappriority !== undefined ? configproductlist.lappriority : "N/A"}</td>
                            <td>{laptype !== undefined ? laptype : "N/A"}</td>
                            </tr>
                          );
                    // }
                    
                  }
                );
                return listDetails;
              }
			  else {
				return (
				  <tr>
					<td colspan="11" className="v-align-nr">
					  No Result
					</td>
				  </tr>
				);
			  }
            } else {
              return (
                <tr>
                  <td colspan="11" className="v-align-nr">
                    No Result
                  </td>
                </tr>
              );
            }
          }else {
            return (
              <tr>
                <td colspan="11" className="v-align-nr">
                  No Result
                </td>
              </tr>
            );
          }
        }

		configimageblist() {
			let configproductimglist = this.state.configproductimglist;
			console.log(configproductimglist,'configproductimaglist')
			let productthumbimage = configproductimglist.length > 0 && configproductimglist.map((img, index) => {
				if(img.type === 'main') {
					let thumb_img = img.config_image;
					let thumb_img_path = img.config_image_path;
					return (
						<>
						<span><img src={mediaUrl + thumb_img_path + thumb_img} width={200} height={200} alt={"Thumb Image" + (index + 1)} /></span>
						{/* <a href="javascript:void(0);" onClick={this.removeImageConfigbyindex.bind(this,index,'configtryout')}>X</a> */}
						</>
						
					);
				}
			});
		
			// Check if there are images to display
			if (productthumbimage && productthumbimage.length > 0) {
				return (
					<>
						
						{productthumbimage}
					</>
				);
			} else {
				return null; // Return null if there are no images
			}
		}
		tryoutthumblist() {
			let configproductimglist = this.state.configtryoutimglist;
			let tryoutthumbimage = configproductimglist.length > 0 && configproductimglist.map((img, index) => {
				if(img.type === 'thumb') {
					let thumb_img = img.tryout_image;
					let thumb_img_path = img.tryout_image_path;
					return (
						<img src={mediaUrl + thumb_img_path + thumb_img} width={200} height={200} alt={"Thumb Image" + (index + 1)} />
					);
				}
			});
			// Check if there are thumbnail images to display
			if (tryoutthumbimage && tryoutthumbimage.length > 0) {
				return (
					<>
						<p><b>Element Images:</b></p>
						{tryoutthumbimage}
					</>
				);
			} else {
				return null; // Return null if there are no thumbnail images
			}
		}
  render() {
	const tableData = this.configproductlist();

		{console.log(this.state.productdetail,'productdetails')}
	const navigate = this.props.navigate;
	const {selectedOption} = this.state;

	let imageslist = [];
	let indexlength =  imageslist.length;

	const imgThumblist =  this.fileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index,'prodcut')}>X</a>
		</li>
	));

	const imgtryoutThumblist = this.tryoutfileArray.map((url,index) => (
		<li className="thumb" key={index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeTryoutImagebyindex.bind(this,index,'tryout')}>X</a>
		</li>
	));

	var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;
	// var	tryoutpreview = <img className="img_class" src={this.state.tryout_image_preview} alt="" />;

		
	const tryoutpreview =  this.configfileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImageConfigbyindex.bind(this,index,'configtryout')}>X</a>
		</li>
	));

	            // this.configfileArray.push(URL.createObjectURL(this.fileObj2[0][i]))
				// this.configimageArray.push(this.fileObj2[0][i])
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="config-product" />  

		<div id="location-error"></div>
		<div className="content">	
			<div className="content-wrapper">
				<div class="content-wrapper-before"></div>
					<div className="form-wrapper leaa-admin-head card">
					<div className="success_message"></div>
					<div className="back-btn-lux">
						<a onClick={() => navigate(-1)}>Back</a>    
					</div>
					<br></br>
					<br></br>
					<div className="form-row">
					<div className="form-left">
						{this.productlist()}
						{/* {this.tryoutthumblist()}	 */}
				     </div>
					</div>
					
					<div className="title">
						<h4>Add Configure Item</h4>				
					</div>
					<form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
						<div className="form-row">
							<div className="form-left">
								
								<div className="form-group">
									<label>Upload Main  Image (Allowed jpeg, png, svg)<span class="required" style={{ color: "red" }} >* </span></label>
									
									<div className="choose-file">
									{this.state.isHidden && <div className="image_success"></div>}
									<span className="profile_btn">
										{/* <input type="file" name="file" onChange={this.onSelcectImage.bind(this,'tryout')} multiple /> */} 
										<input type="file" name="file" onChange={this.uploadMultipleFiles.bind(this,'configtryout')} multiple />
										</span>
									</div>
									{this.state.trialon === 'main' ? (
										<div className="errorconfig_image"></div>
									) : null}
									<ul className="carimagelisting">
										{tryoutpreview}
									</ul>
									{this.configimageblist()}
								</div>
								<div className="form-group">
										<input
											type="checkbox"
											value="element"
											name="trialon"
											className='trialon'
											checked={this.state.trialon === 'element'}
											onChange={this.handleInputChange}
											/>
									<label>Add Element Configurations:</label>
									{/* <input type="radio" value="main" name="trialon" className='trialon' checked={this.state.trialon === 'main'}  onChange={this.handleInputChange}  />Main Image */}
											
									{/* <div className="errortrialon"></div>   */}
								</div>
							</div>
							<div className="form-right">
								

								
								{/* <div className="form-group"> 
									<label>Main Thumbnail Image<span class="required" style={{ color: "red" }} ></span></label>
									<div className="choose-file">
										{this.state.isHidden && <div className="image_success"></div>}
										<span className="profile_btn">
										<input type="file" className="form-control" name="file" onChange={this.uploadMultipleFiles.bind(this,'product')} multiple /></span>
									</div>
									<ul className="carimagelisting">
										{imgThumblist}
									</ul>
									<div className="erroruser_image"></div>
								</div> */}
							</div>	
						</div>	
						<div className="form-row">
							<div className="form-group"> 
									{/* <div className="choose-file"> */}
										{this.state.isHidden && <div className="image_success"></div>}
										<span className="profile_btn">
										<Addimageinputsimple
											onChange={(e) => this.uploadMultipleConfigFiles('tryout', e)}
									  	     imageList={imgtryoutThumblist}
											 trialon={this.state.trialon}
											 onChangeRow={this.handleRowChange}
                                             onChangeChild={(data) => this.handleChangeFromChild(data)}
									   	/>
										</span>
									{/* </div> */}
									<div className="errortryout_image"></div>
								</div>
						</div>
						{this.state.trialon === 'main' ? (
							<div className="btn-group">	
							 <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >	Submit</button>
						</div>
						) : 
							<div className="btn-group export">	
							<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >	Submit</button>
							</div>
					}
						
					</form>
					<div className="overflow">
				
			{tableData.length > 0 && (		
                <Table striped bordered hover>
                  <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Element Name</th>
						<th>Image</th>
                        <th>X axis</th>
                        <th>Y axis</th>
						<th>Lap Priority</th>
						
                        <th>Laptype</th>
                    </tr>
                  </thead>
                  <tbody>{this.configproductlist()}</tbody>
                </Table>
				  )}
					<div>
						<div className="view-details-two">
							
							
						</div>
						<div className="view-details-two">
							
							
						</div>
					</div>
					
                 {/* )} */}
			</div>
				</div>
			</div>	
		</div>
    </div>
    );
  }
}


export default (withRouter(Addconfig));